import { IGeneralVault, IGeneralVault__factory } from '../../contract-types';
import IERC20ServiceInterface from '../../interfaces/ERC20';
import GeneralVaultInterface from '../../interfaces/v2/GeneralVault';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  VaultConfig,
  ProtocolAction,
  transactionType,
  tStringDecimalUnits,
} from '../../types';

import { VaultValidator } from '../../validators/methodValidators';
import {
  GVDepositCollateralParamsType,
  GVWithdrawCollateralParamsType,
} from '../../types/GeneralVaultMethodTypes';
import {
  IsEthAddress,
  IsPositiveAmount,
  IsPositiveOrMinusOneAmount,
} from '../../validators/paramValidators';
import BaseService from '../BaseService';
import { DEFAULT_APPROVE_AMOUNT } from '../../config';
import { getTxValue, parseNumber } from '../../utils/parsings';
import { constants } from 'ethers';

export default class YearnFBeetsVault
  extends BaseService<IGeneralVault>
  implements GeneralVaultInterface
{
  readonly erc20Service: IERC20ServiceInterface;
  readonly vaultAddress: string;
  readonly vaultConfig: VaultConfig | undefined;

  constructor(
    config: Configuration,
    erc20Service: IERC20ServiceInterface,
    vaultConfig: VaultConfig | undefined
  ) {
    super(config, IGeneralVault__factory);
    this.erc20Service = erc20Service;
    this.vaultConfig = vaultConfig;

    const { VAULT } = this.vaultConfig || {};

    this.vaultAddress = VAULT || '';
  }

  @VaultValidator
  public async depositCollateral(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveAmount('_amount')
    { _user, _asset, _amount }: GVDepositCollateralParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(_asset);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      _amount,
      reserveDecimals
    );

    const approved = await isApproved(
      _asset,
      _user,
      this.vaultAddress,
      _amount
    );
    if (!approved) {
      const approveTx: EthereumTransactionTypeExtended = approve(
        _user,
        _asset,
        this.vaultAddress,
        DEFAULT_APPROVE_AMOUNT
      );
      txs.push(approveTx);
    }

    const vaultContract: IGeneralVault = this.getContractInstance(
      this.vaultAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        vaultContract.populateTransaction.depositCollateral(
          _asset,
          convertedAmount
        ),
      from: _user,
      value: getTxValue(_asset, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.depositCollateral
      ),
    });

    return txs;
  }

  @VaultValidator
  public async withdrawCollateral(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveOrMinusOneAmount('_amount')
    @IsEthAddress('_to')
    { _user, _asset, _amount, _to }: GVWithdrawCollateralParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { decimalsOf }: IERC20ServiceInterface = this.erc20Service;
    const decimals: number = await decimalsOf(_asset);

    const convertedAmount: tStringDecimalUnits =
      _amount === '-1'
        ? constants.MaxUint256.toString()
        : parseNumber(_amount, decimals);

    const vaultContract: IGeneralVault = this.getContractInstance(
      this.vaultAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        vaultContract.populateTransaction[
          'withdrawCollateral(address,uint256,address)'
        ](_asset, convertedAmount, _to),
      from: _user,
      action: ProtocolAction.withdrawCollateral,
    });

    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.DLP_ACTION,
        gas: this.generateTxPriceEstimation(
          [],
          txCallback,
          ProtocolAction.withdrawCollateral
        ),
      },
    ];
  }
}
