import { TxBuilderConfig } from '../types';

export const defaultConfig: TxBuilderConfig = {
  lendingPool: {
    mainnet: {
      proto: {
        LENDING_POOL: '0x2A4d822BFB34d377c978F28a6C332Caa2fF87530',
      },
    },
    kovan: {
      proto: {
        LENDING_POOL: '0xE0fBa4Fc209b4948668006B2bE61711b7f465bAe',
      },
    },
    polygon: {
      proto: {
        LENDING_POOL: '0x8dFf5E27EA6b7AC08EbFdf9eB090F32ee9a30fcf',
      },
    },
    mumbai: {
      proto: {
        LENDING_POOL: '0x9198F13B08E299d85E096929fA9781A1E3d5d827',
      },
    },
    goerli: {
      proto: {
        LENDING_POOL: '0xCB52d58615757F9Af055c6d5e076164e325b5C69',
      },
    },
    ftm: {
      proto: {
        LENDING_POOL: '0x7FF2520Cd7b76e8C49B5DB51505b842d665f3e9A',
      },
    },
    ftm_test: {
      proto: {
        LENDING_POOL: '0x7237348D79053D86b4cDD553462AA1D34050a774',
      },
    },
  },
  // staking: {
  //   mainnet: {
  //     aave: {
  //       TOKEN_STAKING: '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
  //       STAKING_REWARD_TOKEN: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  //       STAKING_HELPER: '0xce0424653fb2fd48ed1b621bdbd60db16b2e388a',
  //     },
  //     balancer: {
  //       TOKEN_STAKING: '0xa1116930326D21fB917d5A27F1E9943A9595fb47',
  //       STAKING_REWARD_TOKEN: '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9',
  //     },
  //   },
  //   kovan: {
  //     aave: {
  //       TOKEN_STAKING: '0xf2fbf9A6710AfDa1c4AaB2E922DE9D69E0C97fd2',
  //       STAKING_REWARD_TOKEN: '0xb597cd8d3217ea6477232f9217fa70837ff667af',
  //       STAKING_HELPER: '0xf267aCc8BF1D8b41c89b6dc1a0aD8439dfbc890c',
  //     },
  //     balancer: {
  //       TOKEN_STAKING: '0x31ce45Ab6E26C72c47C52c27498D460099545ef2',
  //       STAKING_REWARD_TOKEN: '0xb597cd8d3217ea6477232f9217fa70837ff667af',
  //     },
  //   },
  // },
  incentives: {
    mainnet: {
      INCENTIVES_CONTROLLER: '0xd784927Ff2f95ba542BfC824c8a8a98F3495f6b5',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x4da27a545c0c5b758a6ba100e3a049001de870f5',
    },
    polygon: {
      INCENTIVES_CONTROLLER: '0x357d51124f59836ded84c8a1730d72b749d8bc23',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    mumbai: {
      INCENTIVES_CONTROLLER: '0xd41aE58e803Edf4304334acCE4DC4Ec34a63C644',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x9c3c9283d3e44854697cd22d3faa240cfb032889',
    },
    fork: {
      INCENTIVES_CONTROLLER: '0xA897716BA0c7603B10b8b2854c104912a6058542',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x7Ff897A14796072a1d23e0002f3Dca9Bc80af677',
    },
    goerli: {
      INCENTIVES_CONTROLLER: '0xD5F34C2Fc6e533e409Dc06B9fE5a6D4130CE9ACf',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x9d0fae54966301F9A6590cD870EAd34bA388Dd45',
    },
    ftm: {
      INCENTIVES_CONTROLLER: '0xcdA2B5Cd654be0DBA19E4064c583642741712560',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0x6BA22b71D83e44D611bB9a0771A5144d869baf57',
    },
    ftm_test: {
      INCENTIVES_CONTROLLER: '0x0920Eb8309cbAd2c67521bF98042a3a199b936b8',
      INCENTIVES_CONTROLLER_REWARD_TOKEN:
        '0xaDe28f8cEcBC6E674C661212B1816E8d25B212B6',
    },
  },
};
