import { ethers, providers } from 'ethers';
import FaucetInterface from './interfaces/Faucet';
import IERC20ServiceInterface from './interfaces/ERC20';
import ERC20Service from './services/ERC20';
import FaucetService from './services/Faucet';
import WidoServiceInterface from './interfaces/Wido';
import {
  ChainId,
  Configuration,
  DefaultProviderKeys,
  Network,
  TxBuilderConfig,
} from './types';
import IncentivesController, {
  IncentivesControllerInterface,
} from './services/IncentivesController';
import { defaultConfig } from './config/defaultConfig';
import StableYieldDistribution, {
  StableYieldDistributionInterface,
} from './services/StableYieldDistribution';
import {
  VariableYieldDistribution,
  VariableYieldDistributionInterface,
} from './services/VariableYieldDistribution';
import WidoService from './services/WidoService';

export default class BaseTxBuilder {
  readonly configuration: Configuration;

  public erc20Service: IERC20ServiceInterface;

  public incentiveService: IncentivesControllerInterface;

  public stableYieldDistribution: StableYieldDistributionInterface;

  public variableYieldDistribution: VariableYieldDistributionInterface;

  public widoService: WidoServiceInterface;

  readonly faucets: { [market: string]: FaucetInterface };

  readonly txBuilderConfig: TxBuilderConfig;

  constructor(
    network: Network = Network.mainnet,
    injectedProvider?: providers.Provider | string | undefined,
    defaultProviderKeys?: DefaultProviderKeys,
    config: TxBuilderConfig = defaultConfig
  ) {
    this.txBuilderConfig = config;
    let provider: providers.Provider;
    // TODO: this is probably not enough as we use network down the road
    const chainId = ChainId[network];

    if (!injectedProvider) {
      if (defaultProviderKeys && Object.keys(defaultProviderKeys).length > 1) {
        provider = ethers.getDefaultProvider(network, defaultProviderKeys);
      } else {
        provider = ethers.getDefaultProvider(network);
        console.log(
          `These API keys are a provided as a community resource by the backend services for low-traffic projects and for early prototyping.
          It is highly recommended to use own keys: https://docs.ethers.io/v5/api-keys/`
        );
      }
    } else if (typeof injectedProvider === 'string') {
      provider = new providers.StaticJsonRpcProvider(injectedProvider, chainId);
    } else if (injectedProvider instanceof providers.Provider) {
      provider = injectedProvider;
    } else {
      provider = new providers.Web3Provider(injectedProvider, chainId);
    }

    this.configuration = { network, provider };

    this.erc20Service = new ERC20Service(this.configuration);

    this.incentiveService = new IncentivesController(
      this.configuration,
      this.txBuilderConfig.incentives?.[network]
    );

    this.stableYieldDistribution = new StableYieldDistribution(
      this.configuration
    );

    this.variableYieldDistribution = new VariableYieldDistribution(
      this.configuration
    );
    this.faucets = {};

    this.widoService = new WidoService(this.configuration, this.erc20Service);
  }

  public getFaucet = (market: string): FaucetInterface => {
    if (!this.faucets[market]) {
      const { network } = this.configuration;
      this.faucets[market] = new FaucetService(
        this.configuration,
        this.txBuilderConfig.lendingPool?.[network]?.[market]
      );
    }
    return this.faucets[market];
  };
}
