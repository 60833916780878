import { GraphQLClient, gql } from 'graphql-request';

export const CONVEX_GRAPHQL_ENDPOINT =
  'https://api.thegraph.com/subgraphs/name/convex-community/curve-pools';
export const CONVEX_POOLS_QUERY = (timestamp: number) => gql`
  {
    pools {
      id
      name
      lpToken
      baseApr
      crvApr
      cvxApr
      extraRewardsApr
      snapshots(where: {timestamp_gte: ${timestamp}}) {
        baseApr
        crvApr
        cvxApr
        extraRewardsApr
      }
    }
  }
`;

export const convexGraphQLClient = new GraphQLClient(CONVEX_GRAPHQL_ENDPOINT);

// id
// name
// lpToken
// crvApr
// cvxApr
// baseApr
// extraRewardsApr
// extras
// snapshots(where: {timestamp_gte: "1655912200"}) {
//   baseApr
//   crvApr
//   cvxApr
//   extraRewardsApr
// }
