import { providers, BigNumber, BytesLike, PopulatedTransaction } from 'ethers';

export type tEthereumAddress = string;
export type tStringCurrencyUnits = string; // ex 2.5 eth
export type tStringDecimalUnits = string; // ex 2500000000000000000
export type ENS = string; // something.eth

/** InterestRate options */
export enum InterestRate {
  None = 'None',
  Variable = 'Variable',
}

export enum Market {
  Proto = 'proto',
}

export enum Network {
  mainnet = 'mainnet',
  ropsten = 'ropsten',
  goerli = 'goerli',
  kovan = 'kovan',
  polygon = 'polygon',
  fork = 'fork',
  mumbai = 'mumbai',
  polygon_fork = 'polygon_fork',
  avalanche = 'avalanche',
  avalanche_fork = 'avalanche_fork',
  fuji = 'fuji',
  arbitrum_one = 'arbitrum_one',
  arbitrum_rinkeby = 'arbitrum_rinkeby',
  ftm = 'ftm',
  ftm_test = 'ftm_test',
}

export enum ChainId {
  mainnet = 1,
  ropsten = 3,
  goerli = 5,
  kovan = 42,
  polygon = 137,
  ftm = 250,
  fork = 1337,
  ftm_test = 4002,
  mumbai = 80001,
  polygon_fork = 1338,
  avalanche = 43114,
  avalanche_fork = 1337,
  fuji = 43113, // avalanche test network
  arbitrum_one = 42161,
  arbitrum_rinkeby = 421611,
}

export type IncentivesConfig = {
  INCENTIVES_CONTROLLER: tEthereumAddress;
  INCENTIVES_CONTROLLER_REWARD_TOKEN: tEthereumAddress;
};

export type LendingPoolMarketConfig = {
  LENDING_POOL: tEthereumAddress;
  FAUCET?: tEthereumAddress;
};

export type VaultConfig = {
  VAULT: tEthereumAddress;
};

export type LeverageSwapConfig = {
  LEVSWAPPER: tEthereumAddress;
};

export type LendingPoolConfig = {
  [network: string]: {
    [market: string]: LendingPoolMarketConfig;
  };
};

export type TxBuilderConfig = {
  incentives?: {
    [network: string]: IncentivesConfig;
  };
  lendingPool?: LendingPoolConfig;
  lidoVault?: {
    [network: string]: VaultConfig;
  };
  convexFRAX3CRVVault?: {
    [network: string]: VaultConfig;
  };
  convexDAIUSDCUSDTSUSDVault?: {
    [network: string]: VaultConfig;
  };
  convexIronBankVault?: {
    [network: string]: VaultConfig;
  };
  convexFRAXUSDCVault?: {
    [network: string]: VaultConfig;
  };
  convexMIM3CRVVault?: {
    [network: string]: VaultConfig;
  };
  convexTUSDFRAXBPVault?: {
    [network: string]: VaultConfig;
  };
  yearnVault?: {
    [network: string]: VaultConfig;
  };
  yearnWETHVault?: {
    [network: string]: VaultConfig;
  };
  yearnWBTCVault?: {
    [network: string]: VaultConfig;
  };
  yearnBOOVault?: {
    [network: string]: VaultConfig;
  };
  yearnFBeetsVault?: {
    [network: string]: VaultConfig;
  };
  yearnLINKVault?: {
    [network: string]: VaultConfig;
  };
  yearnSPELLVault?: {
    [network: string]: VaultConfig;
  };
  yearnCRVVault?: {
    [network: string]: VaultConfig;
  };
  tombFtmBeefyVault?: {
    [network: string]: VaultConfig;
  };
  tombMiMaticBeefyVault?: {
    [network: string]: VaultConfig;
  };
  basedMiMaticBeefyVault?: {
    [network: string]: VaultConfig;
  };
  convexFRAX3CRVLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
  convexDAIUSDCUSDTSUSDLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
  convexFRAXUSDCLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
  convexIRONBANKLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
  convexMIM3CRVLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
  convexTUSDFRAXBPLevSwap?: {
    [network: string]: LeverageSwapConfig;
  };
};

export enum eEthereumTxType {
  ERC20_APPROVAL = 'ERC20_APPROVAL',
  DLP_ACTION = 'DLP_ACTION',
  REWARD_ACTION = 'REWARD_ACTION',
  FAUCET_MINT = 'FAUCET_MINT',
}

export enum ProtocolAction {
  default = 'default',
  withdraw = 'withdraw',
  deposit = 'deposit',
  liquidationCall = 'liquidationCall',
  repay = 'repay',
  borrow = 'borrow',
  depositCollateral = 'depositCollateral',
  withdrawCollateral = 'withdrawCollateral',
  enterPositionWithFlashloan = 'enterPositionWithFlashloan',
  withdrawWithFlashloan = 'withdrawWithFlashloan',
  executeOrder = 'executeOrder',
  zapDeposit = 'zapDeposit',
  zapLeverageWithFlashloan = 'zapLeverageWithFlashloan',
}

export type GasRecommendationType = {
  [action: string]: {
    limit: string;
    recommended: string;
  };
};

export type GeneratedTx = {
  tx: transactionType;
  gas: {
    price: string;
    limit: string;
  };
};

export type transactionType = {
  value?: string;
  from?: string;
  to?: string;
  nonce?: number;
  gasLimit?: BigNumber;
  gasPrice?: BigNumber;
  data?: string;
  chainId?: number;
};

export type AddressModel = {
  ADDRESS_PROVIDER_ADDRESS: tEthereumAddress;
  LENDINGPOOL_ADDRESS: tEthereumAddress;
  LENDINGPOOL_CORE_ADDRESS: tEthereumAddress;
  INCENTIVES_CONTROLLER: tEthereumAddress;
  INCENTIVES_CONTROLLER_REWARD_TOKEN: tEthereumAddress;
};

export type tCommonContractAddressBetweenMarkets = Pick<
  AddressModel,
  'INCENTIVES_CONTROLLER' | 'INCENTIVES_CONTROLLER_REWARD_TOKEN'
>;

export type tDistinctContractAddressBetweenMarkets = Pick<
  AddressModel,
  | 'ADDRESS_PROVIDER_ADDRESS'
  | 'LENDINGPOOL_ADDRESS'
  | 'LENDINGPOOL_CORE_ADDRESS'
>;

export type tDistinctContractAddressBetweenMarketsV2 = Pick<
  AddressModel,
  'LENDINGPOOL_ADDRESS'
>;

export type ContractAddresses = {
  [contractName: string]: tEthereumAddress;
};

export type Configuration = {
  network: Network;
  provider: providers.Provider;
};

export type EthereumTransactionTypeExtended = {
  txType: eEthereumTxType;
  tx: () => Promise<transactionType>;
  gas: GasResponse;
};

export type TransactionGenerationMethod = {
  rawTxMethod: () => Promise<PopulatedTransaction>;
  from: tEthereumAddress;
  value?: string;
  gasSurplus?: number;
  action?: ProtocolAction;
};

export type TransactionGasGenerationMethod = {
  txCallback: () => Promise<transactionType>;
  action?: ProtocolAction;
};

export type GasType = {
  gasLimit: string | undefined;
  gasPrice: string;
};
export type GasResponse = (force?: boolean) => Promise<GasType | null>;

export type TokenMetadataType = {
  name: string;
  symbol: string;
  decimals: number;
  address: string;
};

export type DefaultProviderKeys = {
  etherscan?: string;
  infura?: string;
  alchemy?: string;
};

export type CommonConfigType = {
  [network: string]: tCommonContractAddressBetweenMarkets;
};

export type LendingPoolConfigType = {
  [pool: string]: {
    [network: string]: tDistinctContractAddressBetweenMarketsV2;
  };
};

export type EnabledNetworksType = {
  lendingPool: {
    [market: string]: Network[];
  };
  incentivesController: Network[];
};

export type PermitSignature = {
  amount: tStringCurrencyUnits;
  deadline: string;
  v: number;
  r: BytesLike;
  s: BytesLike;
};
