import { constants } from 'ethers';
import {
  IVariableYieldDistribution,
  IVariableYieldDistribution__factory,
} from '../contract-types';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '../types';
import { YieldDistributionValidator } from '../validators/methodValidators';
import { IsEthAddress, IsEthAddressArray } from '../validators/paramValidators';
import BaseService from './BaseService';

export type VariableYieldsMethodType = {
  user: string;
  assets: string[];
  to?: string;
  distributorAddress: string;
};

export interface VariableYieldDistributionInterface {
  claimRewards: (
    args: VariableYieldsMethodType
  ) => EthereumTransactionTypeExtended[];
}

export class VariableYieldDistribution
  extends BaseService<IVariableYieldDistribution>
  implements VariableYieldDistributionInterface
{
  constructor(config: Configuration) {
    super(config, IVariableYieldDistribution__factory);
  }

  @YieldDistributionValidator
  public claimRewards(
    @IsEthAddress('user')
    @IsEthAddress('distributorAddress')
    @IsEthAddress('to')
    @IsEthAddressArray('assets')
    { user, assets, to, distributorAddress }: VariableYieldsMethodType
  ): EthereumTransactionTypeExtended[] {
    const rewardContract: IVariableYieldDistribution =
      this.getContractInstance(distributorAddress);
    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: async () =>
        rewardContract.populateTransaction.claimRewards(
          assets,
          new Array(assets.length).fill(constants.MaxUint256.toString()),
          to ?? user
        ),
      from: user,
    });

    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.REWARD_ACTION,
        gas: this.generateTxPriceEstimation([], txCallback),
      },
    ];
  }
}
