import BigNumber from 'bignumber.js';

import {
  BigNumberValue,
  valueToBigNumber,
  valueToZDBigNumber,
  normalize,
  pow10,
  normalizeBN,
} from '../helpers/bignumber';
import {
  calculateAvailableBorrowsETH,
  calculateHealthFactorFromBalances,
  getCompoundedBalance,
  getCompoundedStableBalance,
  calculateAverageRate,
  LTV_PRECISION,
  calculateCompoundedInterest,
  getLinearBalance,
  VAULT_FEE,
} from '../helpers/pool-math';
import { RAY, rayDiv, rayMul, rayPow } from '../helpers/ray-math';
import {
  ComputedUserReserve,
  ReserveData,
  UserReserveData,
  UserSummaryData,
  ReserveRatesData,
  ComputedReserveData,
  Supplies,
  ReserveSupplyData,
  RewardsInformation,
  ReserveRewardData,
  ReserveTokenRewards,
  ConvexPoolData,
} from './types';
import {
  ETH_DECIMALS,
  RAY_DECIMALS,
  SECONDS_PER_YEAR,
  USD_DECIMALS,
} from '../helpers/constants';
// import { lidoGraphQLClient, REWARD_QUERY } from './graphql/lido';
import { convexGraphQLClient, CONVEX_POOLS_QUERY } from './graphql/convex';
import { ChainId, Network } from '..';
import axios from 'axios';

export function getEthAndUsdBalance(
  balance: BigNumberValue,
  priceInEth: BigNumberValue,
  decimals: number,
  usdPriceEth: BigNumberValue
): [string, string] {
  const balanceInEth = valueToZDBigNumber(balance)
    .multipliedBy(priceInEth)
    .dividedBy(pow10(decimals));
  const balanceInUsd = balanceInEth
    .multipliedBy(pow10(USD_DECIMALS))
    .dividedBy(usdPriceEth)
    .toFixed(0);
  return [balanceInEth.toString(), balanceInUsd];
}

/*
type ComputeUserReserveDataPoolReserve = Pick<
  ReserveData,
  | 'price'
  | 'decimals'
  | 'liquidityIndex'
  | 'liquidityRate'
  | 'lastUpdateTimestamp'
  | 'variableBorrowIndex'
  | 'variableBorrowRate'
>;

type ComputeUserReserveDataUserReserve = Pick<
  UserReserveData,
  | 'scaledATokenBalance'
  | 'scaledVariableDebt'
  | 'principalStableDebt'
  | 'stableBorrowRate'
  | 'stableBorrowLastUpdateTimestamp'
>;
*/

export function computeUserReserveData(
  poolReserve: ReserveData,
  userReserve: UserReserveData,
  usdPriceEth: BigNumberValue,
  currentTimestamp: number,
  rewardsInfo: RewardsInformation
): ComputedUserReserve {
  const {
    price: { priceInEth },
    decimals,
  } = poolReserve;
  const underlyingBalance = getLinearBalance(
    userReserve.scaledATokenBalance,
    poolReserve.liquidityIndex,
    poolReserve.liquidityRate,
    poolReserve.lastUpdateTimestamp,
    currentTimestamp
  ).toString();
  const [underlyingBalanceETH, underlyingBalanceUSD] = getEthAndUsdBalance(
    underlyingBalance,
    priceInEth,
    decimals,
    usdPriceEth
  );

  const variableBorrows = getCompoundedBalance(
    userReserve.scaledVariableDebt,
    poolReserve.variableBorrowIndex,
    poolReserve.variableBorrowRate,
    poolReserve.lastUpdateTimestamp,
    currentTimestamp
  ).toString();

  const [variableBorrowsETH, variableBorrowsUSD] = getEthAndUsdBalance(
    variableBorrows,
    priceInEth,
    decimals,
    usdPriceEth
  );

  const stableBorrows = getCompoundedStableBalance(
    userReserve.principalStableDebt,
    poolReserve.stableBorrowRate,
    userReserve.stableBorrowLastUpdateTimestamp,
    currentTimestamp
  ).toString();

  const [stableBorrowsETH, stableBorrowsUSD] = getEthAndUsdBalance(
    stableBorrows,
    priceInEth,
    decimals,
    usdPriceEth
  );
  const { totalLiquidity, totalStableDebt, totalVariableDebt } =
    calculateSupplies(
      {
        totalScaledVariableDebt: poolReserve.totalScaledVariableDebt,
        variableBorrowIndex: poolReserve.variableBorrowIndex,
        variableBorrowRate: poolReserve.variableBorrowRate,
        totalPrincipalStableDebt: poolReserve.totalPrincipalStableDebt,
        averageStableRate: poolReserve.averageStableRate,
        availableLiquidity: poolReserve.availableLiquidity,
        stableDebtLastUpdateTimestamp:
          poolReserve.stableDebtLastUpdateTimestamp,
        lastUpdateTimestamp: poolReserve.lastUpdateTimestamp,
      },
      currentTimestamp
    );

  const aTokenRewards = totalLiquidity.gt(0)
    ? calculateRewards(
        userReserve.scaledATokenBalance,
        poolReserve.aTokenIncentivesIndex,
        userReserve.aTokenincentivesUserIndex,
        rewardsInfo.incentivePrecision,
        rewardsInfo.rewardTokenDecimals,
        poolReserve.aIncentivesLastUpdateTimestamp,
        poolReserve.aEmissionPerSecond,
        rayDiv(totalLiquidity, poolReserve.liquidityIndex),
        currentTimestamp,
        rewardsInfo.emissionEndTimestamp
      )
    : '0';

  const [aTokenRewardsETH, aTokenRewardsUSD] = getEthAndUsdBalance(
    aTokenRewards,
    rewardsInfo.rewardTokenPriceEth,
    rewardsInfo.rewardTokenDecimals,
    usdPriceEth
  );

  const vTokenRewards = totalVariableDebt.gt(0)
    ? calculateRewards(
        userReserve.scaledVariableDebt,
        poolReserve.vTokenIncentivesIndex,
        userReserve.vTokenincentivesUserIndex,
        rewardsInfo.incentivePrecision,
        rewardsInfo.rewardTokenDecimals,
        poolReserve.vIncentivesLastUpdateTimestamp,
        poolReserve.vEmissionPerSecond,
        new BigNumber(poolReserve.totalScaledVariableDebt),
        currentTimestamp,
        rewardsInfo.emissionEndTimestamp
      )
    : '0';

  const [vTokenRewardsETH, vTokenRewardsUSD] = getEthAndUsdBalance(
    vTokenRewards,
    rewardsInfo.rewardTokenPriceEth,
    rewardsInfo.rewardTokenDecimals,
    usdPriceEth
  );
  const sTokenRewards = totalStableDebt.gt(0)
    ? calculateRewards(
        userReserve.principalStableDebt,
        poolReserve.sTokenIncentivesIndex,
        userReserve.sTokenincentivesUserIndex,
        rewardsInfo.incentivePrecision,
        rewardsInfo.rewardTokenDecimals,
        poolReserve.sIncentivesLastUpdateTimestamp,
        poolReserve.sEmissionPerSecond,
        new BigNumber(poolReserve.totalPrincipalStableDebt),
        currentTimestamp,
        rewardsInfo.emissionEndTimestamp
      )
    : '0';

  const [sTokenRewardsETH, sTokenRewardsUSD] = getEthAndUsdBalance(
    sTokenRewards,
    rewardsInfo.rewardTokenPriceEth,
    rewardsInfo.rewardTokenDecimals,
    usdPriceEth
  );

  const exactStableBorrowRate = rayPow(
    valueToZDBigNumber(userReserve.stableBorrowRate)
      .dividedBy(SECONDS_PER_YEAR)
      .plus(RAY),
    SECONDS_PER_YEAR
  ).minus(RAY);

  return {
    ...userReserve,
    underlyingBalance,
    underlyingBalanceETH,
    underlyingBalanceUSD,
    variableBorrows,
    variableBorrowsETH,
    variableBorrowsUSD,
    stableBorrows,
    stableBorrowsETH,
    stableBorrowsUSD,
    totalBorrows: valueToZDBigNumber(variableBorrows)
      .plus(stableBorrows)
      .toString(),
    totalBorrowsETH: valueToZDBigNumber(variableBorrowsETH)
      .plus(stableBorrowsETH)
      .toString(),
    totalBorrowsUSD: valueToZDBigNumber(variableBorrowsUSD)
      .plus(stableBorrowsUSD)
      .toString(),
    aTokenRewards,
    aTokenRewardsETH,
    aTokenRewardsUSD,
    vTokenRewards,
    vTokenRewardsETH,
    vTokenRewardsUSD,
    sTokenRewards,
    sTokenRewardsETH,
    sTokenRewardsUSD,
    totalRewards: valueToZDBigNumber(aTokenRewards)
      .plus(vTokenRewards)
      .plus(sTokenRewards)
      .toString(),
    totalRewardsETH: valueToZDBigNumber(aTokenRewardsETH)
      .plus(vTokenRewardsETH)
      .plus(sTokenRewardsETH)
      .toString(),
    totalRewardsUSD: valueToZDBigNumber(aTokenRewardsUSD)
      .plus(vTokenRewardsUSD)
      .plus(sTokenRewardsUSD)
      .toString(),
    stableBorrowAPR: normalize(userReserve.stableBorrowRate, RAY_DECIMALS),
    stableBorrowAPY: normalize(exactStableBorrowRate, RAY_DECIMALS),
  };
}

export function computeRawUserSummaryData(
  poolReservesData: ReserveData[],
  rawUserReserves: UserReserveData[],
  userId: string,
  usdPriceEth: BigNumberValue,
  currentTimestamp: number,
  rewardsInfo: RewardsInformation
): UserSummaryData {
  let totalLiquidityETH = valueToZDBigNumber('0');
  let totalCollateralETH = valueToZDBigNumber('0');
  let totalBorrowsETH = valueToZDBigNumber('0');
  let currentLtv = valueToBigNumber('0');
  let currentLiquidationThreshold = valueToBigNumber('0');

  let totalRewards = valueToBigNumber('0');
  let totalRewardsETH = valueToBigNumber('0');
  let totalRewardsUSD = valueToBigNumber('0');

  const userReservesData = rawUserReserves
    .map((userReserve) => {
      const poolReserve = poolReservesData.find(
        (reserve) => reserve.id === userReserve.reserve.id
      );
      if (!poolReserve) {
        throw new Error(
          'Reserve is not registered on platform, please contact support'
        );
      }
      const computedUserReserve = computeUserReserveData(
        poolReserve,
        userReserve,
        usdPriceEth,
        currentTimestamp,
        rewardsInfo
      );

      totalRewards = totalRewards.plus(computedUserReserve.totalRewards);
      totalRewardsETH = totalRewardsETH.plus(
        computedUserReserve.totalRewardsETH
      );
      totalRewardsUSD = totalRewardsUSD.plus(
        computedUserReserve.totalRewardsUSD
      );
      totalLiquidityETH = totalLiquidityETH.plus(
        computedUserReserve.underlyingBalanceETH
      );
      totalBorrowsETH = totalBorrowsETH
        .plus(computedUserReserve.variableBorrowsETH)
        .plus(computedUserReserve.stableBorrowsETH);

      // asset enabled as collateral
      if (
        poolReserve.usageAsCollateralEnabled &&
        userReserve.usageAsCollateralEnabledOnUser
      ) {
        totalCollateralETH = totalCollateralETH.plus(
          computedUserReserve.underlyingBalanceETH
        );
        currentLtv = currentLtv.plus(
          valueToBigNumber(
            computedUserReserve.underlyingBalanceETH
          ).multipliedBy(poolReserve.baseLTVasCollateral)
        );
        currentLiquidationThreshold = currentLiquidationThreshold.plus(
          valueToBigNumber(
            computedUserReserve.underlyingBalanceETH
          ).multipliedBy(poolReserve.reserveLiquidationThreshold)
        );
      }
      return computedUserReserve;
    })
    .sort((a, b) =>
      a.reserve.symbol > b.reserve.symbol
        ? 1
        : a.reserve.symbol < b.reserve.symbol
        ? -1
        : 0
    );

  if (currentLtv.gt(0)) {
    currentLtv = currentLtv
      .div(totalCollateralETH)
      .decimalPlaces(0, BigNumber.ROUND_DOWN);
  }
  if (currentLiquidationThreshold.gt(0)) {
    currentLiquidationThreshold = currentLiquidationThreshold
      .div(totalCollateralETH)
      .decimalPlaces(0, BigNumber.ROUND_DOWN);
  }

  const healthFactor = calculateHealthFactorFromBalances(
    totalCollateralETH,
    totalBorrowsETH,
    currentLiquidationThreshold
  );

  const totalCollateralUSD = totalCollateralETH
    .multipliedBy(pow10(USD_DECIMALS))
    .dividedBy(usdPriceEth)
    .toString();

  const totalLiquidityUSD = totalLiquidityETH
    .multipliedBy(pow10(USD_DECIMALS))
    .dividedBy(usdPriceEth)
    .toString();

  const totalBorrowsUSD = totalBorrowsETH
    .multipliedBy(pow10(USD_DECIMALS))
    .dividedBy(usdPriceEth)
    .toString();

  const availableBorrowsETH = calculateAvailableBorrowsETH(
    totalCollateralETH,
    totalBorrowsETH,
    currentLtv
  );

  return {
    totalLiquidityUSD,
    totalCollateralUSD,
    totalBorrowsUSD,
    totalRewards: totalRewards.toString(),
    totalRewardsETH: totalRewardsETH.toString(),
    totalRewardsUSD: totalRewardsUSD.toString(),
    id: userId,
    totalLiquidityETH: totalLiquidityETH.toString(),
    totalCollateralETH: totalCollateralETH.toString(),
    totalBorrowsETH: totalBorrowsETH.toString(),
    availableBorrowsETH: availableBorrowsETH.toString(),
    currentLoanToValue: currentLtv.toString(),
    currentLiquidationThreshold: currentLiquidationThreshold.toString(),
    healthFactor: healthFactor.toString(),
    reservesData: userReservesData,
  };
}

export function formatUserSummaryData(
  poolReservesData: ReserveData[],
  rawUserReserves: UserReserveData[],
  userId: string,
  usdPriceEth: BigNumberValue,
  currentTimestamp: number,
  rewardsInfo: RewardsInformation
): UserSummaryData {
  const userData = computeRawUserSummaryData(
    poolReservesData,
    rawUserReserves,
    userId,
    usdPriceEth,
    currentTimestamp,
    rewardsInfo
  );
  const userReservesData = userData.reservesData.map(
    ({ reserve, ...userReserve }): ComputedUserReserve => {
      const reserveDecimals = reserve.decimals;

      const exactStableBorrowRate = rayPow(
        valueToZDBigNumber(userReserve.stableBorrowRate)
          .dividedBy(SECONDS_PER_YEAR)
          .plus(RAY),
        SECONDS_PER_YEAR
      ).minus(RAY);

      return {
        ...userReserve,
        reserve: {
          ...reserve,
          reserveLiquidationBonus: normalize(
            valueToBigNumber(reserve.reserveLiquidationBonus).minus(
              pow10(LTV_PRECISION)
            ),
            4
          ),
        },
        scaledATokenBalance: normalize(
          userReserve.scaledATokenBalance,
          reserveDecimals
        ),
        stableBorrowAPR: normalize(userReserve.stableBorrowRate, RAY_DECIMALS),
        stableBorrowAPY: normalize(exactStableBorrowRate, RAY_DECIMALS),
        variableBorrowIndex: normalize(
          userReserve.variableBorrowIndex,
          RAY_DECIMALS
        ),
        underlyingBalance: normalize(
          userReserve.underlyingBalance,
          reserveDecimals
        ),
        underlyingBalanceETH: normalize(
          userReserve.underlyingBalanceETH,
          ETH_DECIMALS
        ),
        underlyingBalanceUSD: normalize(
          userReserve.underlyingBalanceUSD,
          USD_DECIMALS
        ),
        stableBorrows: normalize(userReserve.stableBorrows, reserveDecimals),
        stableBorrowsETH: normalize(userReserve.stableBorrowsETH, ETH_DECIMALS),
        stableBorrowsUSD: normalize(userReserve.stableBorrowsUSD, USD_DECIMALS),
        variableBorrows: normalize(
          userReserve.variableBorrows,
          reserveDecimals
        ),
        variableBorrowsETH: normalize(
          userReserve.variableBorrowsETH,
          ETH_DECIMALS
        ),
        variableBorrowsUSD: normalize(
          userReserve.variableBorrowsUSD,
          USD_DECIMALS
        ),
        totalBorrows: normalize(userReserve.totalBorrows, reserveDecimals),
        totalBorrowsETH: normalize(userReserve.totalBorrowsETH, ETH_DECIMALS),
        totalBorrowsUSD: normalize(userReserve.totalBorrowsUSD, USD_DECIMALS),
      };
    }
  );
  return {
    id: userData.id,
    reservesData: userReservesData,
    totalLiquidityETH: normalize(userData.totalLiquidityETH, ETH_DECIMALS),
    totalLiquidityUSD: normalize(userData.totalLiquidityUSD, USD_DECIMALS),
    totalCollateralETH: normalize(userData.totalCollateralETH, ETH_DECIMALS),
    totalCollateralUSD: normalize(userData.totalCollateralUSD, USD_DECIMALS),
    totalBorrowsETH: normalize(userData.totalBorrowsETH, ETH_DECIMALS),
    totalBorrowsUSD: normalize(userData.totalBorrowsUSD, USD_DECIMALS),
    availableBorrowsETH: normalize(userData.availableBorrowsETH, ETH_DECIMALS),
    currentLoanToValue: normalize(userData.currentLoanToValue, 4),
    currentLiquidationThreshold: normalize(
      userData.currentLiquidationThreshold,
      4
    ),
    healthFactor: userData.healthFactor,
    totalRewards: userData.totalRewards,
    totalRewardsETH: userData.totalRewardsETH,
    totalRewardsUSD: userData.totalRewardsUSD,
  };
}

/**
 * Calculates the formatted debt accrued to a given point in time.
 * @param reserve
 * @param currentTimestamp unix timestamp which must be higher than reserve.lastUpdateTimestamp
 */
export function calculateReserveDebt(
  reserve: ReserveData,
  currentTimestamp: number
) {
  const totalVariableDebt = normalize(
    rayMul(
      rayMul(reserve.totalScaledVariableDebt, reserve.variableBorrowIndex),
      calculateCompoundedInterest(
        reserve.variableBorrowRate,
        currentTimestamp,
        reserve.lastUpdateTimestamp
      )
    ),
    reserve.decimals
  );
  const totalStableDebt = normalize(
    rayMul(
      reserve.totalPrincipalStableDebt,
      calculateCompoundedInterest(
        reserve.averageStableRate,
        currentTimestamp,
        reserve.stableDebtLastUpdateTimestamp
      )
    ),
    reserve.decimals
  );
  return { totalVariableDebt, totalStableDebt };
}

const getLidoAPY = async () => {
  try {
    // const data = await lidoGraphQLClient.request(REWARD_QUERY);
    // const apr = data?.totalRewards?.[0]?.apr;
    const response = await axios(
      `https://us-central1-stu-dashboard-a0ba2.cloudfunctions.net/getLidoApr`
    );
    if (response.data) {
      return +response.data / 100;
    } else {
      return 0;
    }
  } catch {
    return 0;
  }
};

const getConvexVaultData = async (network: Network): Promise<Array<any>> => {
  if (network === Network.mainnet) {
    try {
      const WEEK = 604800;
      const timestamp = Math.round(new Date().valueOf() / 1000) - WEEK;
      const response = await convexGraphQLClient.request(
        CONVEX_POOLS_QUERY(timestamp)
      );
      return response.pools as Array<any>;
    } catch (e) {
      return [];
    }
  }
  return [];
};

const getConvexAPYData = (pool: ConvexPoolData) => {
  const len = pool.snapshots.length;
  return len === 0
    ? {
        baseApr: pool.baseApr,
        crvApr: pool.crvApr,
        cvxApr: pool.cvxApr,
        extraRewardsApr: pool.extraRewardsApr,
      }
    : pool.snapshots.reduce(
        (prev, snapshot) => {
          prev.baseApr = valueToBigNumber(prev.baseApr)
            .plus(valueToBigNumber(snapshot.baseApr).dividedBy(len))
            .toString();
          prev.crvApr = valueToBigNumber(prev.crvApr)
            .plus(valueToBigNumber(snapshot.crvApr).dividedBy(len))
            .toString();
          prev.cvxApr = valueToBigNumber(prev.cvxApr)
            .plus(valueToBigNumber(snapshot.cvxApr).dividedBy(len))
            .toString();
          prev.extraRewardsApr = valueToBigNumber(prev.extraRewardsApr)
            .plus(valueToBigNumber(snapshot.extraRewardsApr).dividedBy(len))
            .toString();
          return prev;
        },
        { baseApr: '0', crvApr: '0', cvxApr: '0', extraRewardsApr: '0' }
      );
};

const getConvexVaultAPY = (
  cvData: Array<any>,
  reserve: ReserveData,
  rewardData: ReserveTokenRewards
) => {
  if (reserve.vaultAPY) return reserve.vaultAPY;

  let vaultAPY = new BigNumber(0);
  const pool = cvData?.find(
    (item: any) =>
      item?.lpToken.toLowerCase() === reserve.collateralAsset?.toLowerCase()
  );
  if (pool) {
    const apyData = getConvexAPYData(pool);

    // The portion for stable coins: totalCRV * (1 - incentiveRate) + totalCVX
    vaultAPY = vaultAPY.plus(apyData.cvxApr).plus(
      valueToBigNumber(apyData.crvApr)
        .multipliedBy(10000 - rewardData.incentiveRatio)
        .dividedBy(10000)
    );
  }
  return new BigNumber(10)
    .exponentiatedBy(18)
    .multipliedBy(vaultAPY)
    .toString();
};

const getConvexVaultAPYDetails = (
  cvData: Array<any>,
  reserve: ReserveData,
  rewardData: ReserveTokenRewards
) => {
  const pool = cvData?.find(
    (item: any) =>
      item?.lpToken.toLowerCase() === reserve.collateralAsset?.toLowerCase()
  );
  if (pool) {
    const apyData = getConvexAPYData(pool);

    // The portion for borrowers: baseCurve + totalCRV * incentiveRate
    const vaultAPY = new BigNumber(0)
      .plus(apyData.baseApr)
      .plus(
        valueToBigNumber(apyData.crvApr)
          .multipliedBy(rewardData.incentiveRatio)
          .dividedBy(10000)
      );
    return {
      totalApr: vaultAPY.toString(),
      convex: {
        baseApr: apyData.baseApr,
        crvApr: valueToBigNumber(apyData.crvApr)
          .multipliedBy(rewardData.incentiveRatio)
          .dividedBy(10000)
          .toString(),
      },
    };
  }
  return {};
};

const getYearnVaultData = async (network: Network): Promise<Array<any>> => {
  if (network === Network.ftm) {
    try {
      const response = await axios(
        `https://cache.yearn.finance/v1/chains/${ChainId.ftm}/vaults/get`
      );
      return response.data as Array<any>;
    } catch (e) {
      return [];
    }
  }

  return [];
};

const getYearnVaultAPY = (yvData: Array<any>, reserve: ReserveData) => {
  if (reserve.vaultAPY) return reserve.vaultAPY;

  const vaultAPY =
    yvData?.find(
      (item: any) => item?.address.toLowerCase() === reserve.underlyingAsset
    )?.metadata?.apy?.net_apy || 0;
  return new BigNumber(10)
    .exponentiatedBy(18)
    .multipliedBy(vaultAPY)
    .toString();
};

const getBeefyVaultData = async (
  network: Network
): Promise<{ [key: string]: any }> => {
  if (network === Network.ftm) {
    const response = await axios(`https://api.beefy.finance/apy/breakdown`);
    return response.data as { [key: string]: any };
  }

  return {};
};

const getBeefyVaultAPR = (
  bvData: { [key: string]: any },
  reserve: ReserveData
) => {
  if (reserve.vaultAPY) return reserve.vaultAPY;

  let key = '';
  if (reserve.symbol === 'TOMB_FTM_LP') key = 'tomb-tomb-ftm';
  if (reserve.symbol === 'TOMB_MIMATIC_LP') key = 'tomb-tomb-mai';
  if (reserve.symbol === 'BASED_MIMATIC_LP') key = 'based-based-mai';

  const vaultAPR = bvData[key]?.vaultApr || 0;
  return new BigNumber(10)
    .exponentiatedBy(18)
    .multipliedBy(vaultAPR)
    .toString();
};

export const formatReserves = async (
  reserves: ReserveData[],
  currentTimestamp?: number,
  reserveIndexes30DaysAgo?: ReserveRatesData[],
  rewardTokenPriceEth = '0',
  emissionEndTimestamp?: number
): Promise<ComputedReserveData[]> => {
  const results = reserves.map((reserve) => {
    const reserve30DaysAgo = reserveIndexes30DaysAgo?.find(
      (res) => res.id === reserve.id
    )?.paramsHistory?.[0];

    const availableLiquidity = normalize(
      reserve.availableLiquidity,
      reserve.decimals
    );

    const { totalVariableDebt, totalStableDebt } = calculateReserveDebt(
      reserve,
      currentTimestamp || reserve.lastUpdateTimestamp
    );

    const totalDebt = valueToBigNumber(totalStableDebt).plus(totalVariableDebt);

    const totalLiquidity = totalDebt.plus(availableLiquidity).toString();
    const utilizationRate =
      totalLiquidity !== '0'
        ? totalDebt.dividedBy(totalLiquidity).toString()
        : '0';

    const hasEmission =
      emissionEndTimestamp &&
      emissionEndTimestamp >
        (currentTimestamp || Math.floor(Date.now() / 1000));

    const aIncentivesAPY =
      hasEmission && totalLiquidity !== '0'
        ? calculateIncentivesAPY(
            reserve.aEmissionPerSecond,
            rewardTokenPriceEth,
            totalLiquidity,
            reserve.price.priceInEth
          )
        : '0';

    const vIncentivesAPY =
      hasEmission && totalVariableDebt !== '0'
        ? calculateIncentivesAPY(
            reserve.vEmissionPerSecond,
            rewardTokenPriceEth,
            totalVariableDebt,
            reserve.price.priceInEth
          )
        : '0';

    const sIncentivesAPY =
      hasEmission && totalStableDebt !== '0'
        ? calculateIncentivesAPY(
            reserve.sEmissionPerSecond,
            rewardTokenPriceEth,
            totalStableDebt,
            reserve.price.priceInEth
          )
        : '0';

    const supplyAPY = rayPow(
      valueToZDBigNumber(reserve.liquidityRate)
        .dividedBy(SECONDS_PER_YEAR)
        .plus(RAY),
      SECONDS_PER_YEAR
    ).minus(RAY);

    const variableBorrowAPY = rayPow(
      valueToZDBigNumber(reserve.variableBorrowRate)
        .dividedBy(SECONDS_PER_YEAR)
        .plus(RAY),
      SECONDS_PER_YEAR
    ).minus(RAY);

    const stableBorrowAPY = rayPow(
      valueToZDBigNumber(reserve.stableBorrowRate)
        .dividedBy(SECONDS_PER_YEAR)
        .plus(RAY),
      SECONDS_PER_YEAR
    ).minus(RAY);

    return {
      ...reserve,
      totalVariableDebt,
      totalStableDebt,
      totalLiquidity,
      availableLiquidity,
      utilizationRate,
      aIncentivesAPY,
      vIncentivesAPY,
      sIncentivesAPY,
      totalDebt: totalDebt.toString(),
      price: {
        ...reserve.price,
        priceInEth: normalize(reserve.price.priceInEth, ETH_DECIMALS),
      },
      baseLTVasCollateral: normalize(
        reserve.baseLTVasCollateral,
        LTV_PRECISION
      ),
      reserveFactor: normalize(reserve.reserveFactor, LTV_PRECISION),
      variableBorrowAPR: normalize(reserve.variableBorrowRate, RAY_DECIMALS),
      variableBorrowAPY: normalize(variableBorrowAPY, RAY_DECIMALS),
      avg30DaysVariableBorrowRate: reserve30DaysAgo
        ? calculateAverageRate(
            reserve30DaysAgo.variableBorrowIndex,
            reserve.variableBorrowIndex,
            reserve30DaysAgo.timestamp,
            reserve.lastUpdateTimestamp
          )
        : undefined,
      avg30DaysLiquidityRate: reserve30DaysAgo
        ? calculateAverageRate(
            reserve30DaysAgo.liquidityIndex,
            reserve.liquidityIndex,
            reserve30DaysAgo.timestamp,
            reserve.lastUpdateTimestamp
          )
        : undefined,

      stableBorrowAPR: normalize(reserve.stableBorrowRate, RAY_DECIMALS),
      stableBorrowAPY: normalize(stableBorrowAPY, RAY_DECIMALS),
      supplyAPR: normalize(reserve.liquidityRate, RAY_DECIMALS),
      supplyAPY: normalize(supplyAPY, RAY_DECIMALS),
      yieldAPY: '0',
      variableDepositAPY: normalize(supplyAPY, RAY_DECIMALS),
      liquidityIndex: normalize(reserve.liquidityIndex, RAY_DECIMALS),
      reserveLiquidationThreshold: normalize(
        reserve.reserveLiquidationThreshold,
        4
      ),
      reserveLiquidationBonus: normalize(
        valueToBigNumber(reserve.reserveLiquidationBonus).minus(
          10 ** LTV_PRECISION
        ),
        4
      ),
      totalScaledVariableDebt: normalize(
        reserve.totalScaledVariableDebt,
        reserve.decimals
      ),
      totalPrincipalStableDebt: normalize(
        reserve.totalPrincipalStableDebt,
        reserve.decimals
      ),
      variableBorrowIndex: normalize(reserve.variableBorrowIndex, RAY_DECIMALS),
      aprDetails: {},
    };
  });

  return results;
};

export const calculateReserveAPY = async (
  network: Network,
  reserves: ComputedReserveData[],
  rewards: ReserveRewardData[]
): Promise<ComputedReserveData[]> => {
  let totalSupply = new BigNumber(0);

  const cvData = await getConvexVaultData(network);
  const yvData = await getYearnVaultData(network);
  const bvData = await getBeefyVaultData(network);

  const totalLiquidityForAllAssets = reserves
    .filter((res) => res.borrowingEnabled)
    .reduce((prevValue: BigNumber, reserve: ComputedReserveData) => {
      const reservePrice = valueToBigNumber(reserve.price?.priceInEth) ?? 0;
      return prevValue.plus(
        valueToBigNumber(reserve.totalLiquidity).multipliedBy(reservePrice)
      );
    }, new BigNumber(0));

  // stETH
  const stETHReserve = reserves.find((reserve) => reserve.symbol === 'stETH');
  if (stETHReserve) {
    const stETHPrice = valueToBigNumber(stETHReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      stETHReserve.totalLiquidity
    ).multipliedBy(stETHPrice);
    if (totalCollateral.gt(0)) {
      const stETHAPY = await getLidoAPY();

      const supplyAPYForstETH = totalCollateral
        .multipliedBy(valueToBigNumber(stETHAPY))
        .multipliedBy(1 - VAULT_FEE)
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForstETH);
    }
  }

  // FRAX_3CRV_LP
  const frax3crvReserve = reserves.find(
    (reserve) => reserve.symbol === 'FRAX_3CRV_LP'
  );
  if (frax3crvReserve) {
    const frax3crvPrice =
      valueToBigNumber(frax3crvReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      frax3crvReserve.totalLiquidity
    ).multipliedBy(frax3crvPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === frax3crvReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForFRAX3CRV = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, frax3crvReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);
      totalSupply = totalSupply.plus(supplyAPYForFRAX3CRV);
    }
    frax3crvReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      frax3crvReserve,
      rewardData
    );
  }

  // DAI_USDC_USDT_SUSD_LP
  const daiusdcusdtsudsReserve = reserves.find(
    (reserve) => reserve.symbol === 'DAI_USDC_USDT_SUSD_LP'
  );
  if (daiusdcusdtsudsReserve) {
    const daiusdcusdtsusdPrice =
      valueToBigNumber(daiusdcusdtsudsReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      daiusdcusdtsudsReserve.totalLiquidity
    ).multipliedBy(daiusdcusdtsusdPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === daiusdcusdtsudsReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForDAIUSDCUSDTSUSD = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, daiusdcusdtsudsReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForDAIUSDCUSDTSUSD);
    }
    daiusdcusdtsudsReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      daiusdcusdtsudsReserve,
      rewardData
    );
  }

  // IRON_BANK_LP
  const ironbankReserve = reserves.find(
    (reserve) => reserve.symbol === 'IRON_BANK_LP'
  );
  if (ironbankReserve) {
    const ironbankPrice =
      valueToBigNumber(ironbankReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      ironbankReserve.totalLiquidity
    ).multipliedBy(ironbankPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === ironbankReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForIRONBANK = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, ironbankReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);
      totalSupply = totalSupply.plus(supplyAPYForIRONBANK);
    }
    ironbankReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      ironbankReserve,
      rewardData
    );
  }

  // FRAX_USDC_LP
  const fraxusdcReserve = reserves.find(
    (reserve) => reserve.symbol === 'FRAX_USDC_LP'
  );
  if (fraxusdcReserve) {
    const fraxusdcPrice =
      valueToBigNumber(fraxusdcReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      fraxusdcReserve.totalLiquidity
    ).multipliedBy(fraxusdcPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === fraxusdcReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForFRAXUSDC = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, fraxusdcReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);
      totalSupply = totalSupply.plus(supplyAPYForFRAXUSDC);
    }
    fraxusdcReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      fraxusdcReserve,
      rewardData
    );
  }

  // MIM_3CRV_LP
  const mim3crvReserve = reserves.find(
    (reserve) => reserve.symbol === 'MIM_3CRV_LP'
  );
  if (mim3crvReserve) {
    const mim3crvPrice =
      valueToBigNumber(mim3crvReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      mim3crvReserve.totalLiquidity
    ).multipliedBy(mim3crvPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === mim3crvReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForMIM3CRV = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, mim3crvReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);
      totalSupply = totalSupply.plus(supplyAPYForMIM3CRV);
    }
    mim3crvReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      mim3crvReserve,
      rewardData
    );
  }

  // TUSD_FRAXBP_LP
  const tusdfraxbpReserve = reserves.find(
    (reserve) => reserve.symbol === 'TUSD_FRAXBP_LP'
  );
  if (tusdfraxbpReserve) {
    const tusdfraxbpPrice =
      valueToBigNumber(tusdfraxbpReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      tusdfraxbpReserve.totalLiquidity
    ).multipliedBy(tusdfraxbpPrice);
    const reward = rewards.find(
      (item) => item.underlyingAsset === tusdfraxbpReserve.underlyingAsset
    );
    const rewardData = reward
      ? reward.rewardData
      : <ReserveTokenRewards>{
          incentiveRatio: 0,
        };
    if (totalCollateral.gt(0)) {
      const supplyAPYForTUSDFRAXBP = totalCollateral
        .multipliedBy(
          valueToBigNumber(
            getConvexVaultAPY(cvData, tusdfraxbpReserve, rewardData)
          )
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);
      totalSupply = totalSupply.plus(supplyAPYForTUSDFRAXBP);
    }
    tusdfraxbpReserve.aprDetails = getConvexVaultAPYDetails(
      cvData,
      tusdfraxbpReserve,
      rewardData
    );
  }

  // WFTM
  const wFTMReserve = reserves.find((reserve) => reserve.symbol === 'WFTM');
  if (wFTMReserve) {
    const wFTMPrice = valueToBigNumber(wFTMReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      wFTMReserve.totalLiquidity
    ).multipliedBy(wFTMPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForWFTM = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, wFTMReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForWFTM);
    }
  }

  // WETH
  const wETHReserve = reserves.find((reserve) => reserve.symbol === 'WETH');
  if (wETHReserve) {
    const wETHPrice = valueToBigNumber(wETHReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      wETHReserve.totalLiquidity
    ).multipliedBy(wETHPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForWETH = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, wETHReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForWETH);
    }
  }

  // WBTC
  const wBTCReserve = reserves.find((reserve) => reserve.symbol === 'WBTC');
  if (wBTCReserve) {
    const wBTCPrice = valueToBigNumber(wBTCReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      wBTCReserve.totalLiquidity
    ).multipliedBy(wBTCPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForWBTC = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, wBTCReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForWBTC);
    }
  }

  // BOO
  const booReserve = reserves.find((reserve) => reserve.symbol === 'BOO');
  if (booReserve) {
    const booPrice = valueToBigNumber(booReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      booReserve.totalLiquidity
    ).multipliedBy(booPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForBOO = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, booReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForBOO);
    }
  }

  // LINK
  const linkReserve = reserves.find((reserve) => reserve.symbol === 'LINK');
  if (linkReserve) {
    const linkPrice = valueToBigNumber(linkReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      linkReserve.totalLiquidity
    ).multipliedBy(linkPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForLINK = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, linkReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForLINK);
    }
  }

  // fBEETS
  const fBeetsReserve = reserves.find((reserve) => reserve.symbol === 'fBEETS');
  if (fBeetsReserve) {
    const fBeetsPrice = valueToBigNumber(fBeetsReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      fBeetsReserve.totalLiquidity
    ).multipliedBy(fBeetsPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForfBEETS = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, fBeetsReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForfBEETS);
    }
  }

  // SPELL
  const spellReserve = reserves.find((reserve) => reserve.symbol === 'SPELL');
  if (spellReserve) {
    const spellPrice = valueToBigNumber(spellReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      spellReserve.totalLiquidity
    ).multipliedBy(spellPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForSPELL = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, spellReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForSPELL);
    }
  }

  // CRV
  const crvReserve = reserves.find((reserve) => reserve.symbol === 'CRV');
  if (crvReserve) {
    const crvPrice = valueToBigNumber(crvReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      crvReserve.totalLiquidity
    ).multipliedBy(crvPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForCRV = totalCollateral
        .multipliedBy(valueToBigNumber(getYearnVaultAPY(yvData, crvReserve)))
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForCRV);
    }
  }

  // TOMB_FTM_LP
  const tombFtmLPReserve = reserves.find(
    (reserve) => reserve.symbol === 'TOMB_FTM_LP'
  );
  if (tombFtmLPReserve) {
    const tombFtmPrice =
      valueToBigNumber(tombFtmLPReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      tombFtmLPReserve.totalLiquidity
    ).multipliedBy(tombFtmPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForTombFtmLP = totalCollateral
        .multipliedBy(
          valueToBigNumber(getBeefyVaultAPR(bvData, tombFtmLPReserve))
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForTombFtmLP);
    }
  }

  // TOMB_MIMATIC_LP
  const tombMiMaticLPReserve = reserves.find(
    (reserve) => reserve.symbol === 'TOMB_MIMATIC_LP'
  );
  if (tombMiMaticLPReserve) {
    const tombMiMaticPrice =
      valueToBigNumber(tombMiMaticLPReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      tombMiMaticLPReserve.totalLiquidity
    ).multipliedBy(tombMiMaticPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForTombMiMaticLP = totalCollateral
        .multipliedBy(
          valueToBigNumber(getBeefyVaultAPR(bvData, tombMiMaticLPReserve))
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForTombMiMaticLP);
    }
  }

  // BASED_MIMATIC_LP
  const basedMiMaticLPReserve = reserves.find(
    (reserve) => reserve.symbol === 'BASED_MIMATIC_LP'
  );
  if (basedMiMaticLPReserve) {
    const basedMiMaticPrice =
      valueToBigNumber(basedMiMaticLPReserve.price?.priceInEth) ?? 0;
    const totalCollateral = valueToBigNumber(
      basedMiMaticLPReserve.totalLiquidity
    ).multipliedBy(basedMiMaticPrice);
    if (totalCollateral.gt(0)) {
      const supplyAPYForBasedMiMaticLP = totalCollateral
        .multipliedBy(
          valueToBigNumber(getBeefyVaultAPR(bvData, basedMiMaticLPReserve))
        )
        .dividedBy(10 ** 18)
        .multipliedBy(valueToBigNumber(1 - VAULT_FEE))
        .dividedBy(totalLiquidityForAllAssets);

      totalSupply = totalSupply.plus(supplyAPYForBasedMiMaticLP);
    }
  }

  const results = reserves.map((reserve) => {
    const reservePrice = valueToBigNumber(reserve.price?.priceInEth) ?? 0;
    const totalLiquidity = valueToBigNumber(
      reserve.totalLiquidity
    ).multipliedBy(reservePrice);
    if (!totalLiquidity) {
      return reserve;
    }

    return {
      ...reserve,
      yieldAPY: totalSupply.toString(),
      variableDepositAPY: totalSupply.plus(reserve.supplyAPY).toString(),
    };
  });

  return results;
};
/**
 * Calculates the debt accrued to a given point in time.
 * @param reserve
 * @param currentTimestamp unix timestamp which must be higher than reserve.lastUpdateTimestamp
 */
export function calculateReserveDebtSuppliesRaw(
  reserve: ReserveSupplyData,
  currentTimestamp: number
) {
  const totalVariableDebt = rayMul(
    rayMul(reserve.totalScaledVariableDebt, reserve.variableBorrowIndex),
    calculateCompoundedInterest(
      reserve.variableBorrowRate,
      currentTimestamp,
      reserve.lastUpdateTimestamp
    )
  );
  const totalStableDebt = rayMul(
    reserve.totalPrincipalStableDebt,
    calculateCompoundedInterest(
      reserve.averageStableRate,
      currentTimestamp,
      reserve.stableDebtLastUpdateTimestamp
    )
  );
  return { totalVariableDebt, totalStableDebt };
}

export function calculateSupplies(
  reserve: ReserveSupplyData,
  currentTimestamp: number
): Supplies {
  const { totalVariableDebt, totalStableDebt } =
    calculateReserveDebtSuppliesRaw(reserve, currentTimestamp);

  const totalDebt = totalVariableDebt.plus(totalStableDebt);

  const totalLiquidity = totalDebt.plus(reserve.availableLiquidity);
  return {
    totalVariableDebt,
    totalStableDebt,
    totalLiquidity,
  };
}

export function calculateIncentivesAPY(
  emissionPerSecond: string,
  rewardTokenPriceInEth: string,
  tokenTotalSupplyNormalized: string,
  tokenPriceInEth: string
): string {
  const emissionPerSecondNormalized = normalizeBN(
    emissionPerSecond,
    ETH_DECIMALS
  ).multipliedBy(rewardTokenPriceInEth);
  const emissionPerYear =
    emissionPerSecondNormalized.multipliedBy(SECONDS_PER_YEAR);

  const totalSupplyNormalized = valueToBigNumber(
    tokenTotalSupplyNormalized
  ).multipliedBy(tokenPriceInEth);

  return emissionPerYear.dividedBy(totalSupplyNormalized).toString(10);
}

export function calculateRewards(
  principalUserBalance: string,
  reserveIndex: string,
  userIndex: string,
  precision: number,
  rewardTokenDecimals: number,
  reserveIndexTimestamp: number,
  emissionPerSecond: string,
  totalSupply: BigNumber,
  currentTimestamp: number,
  emissionEndTimestamp: number
): string {
  const actualCurrentTimestamp =
    currentTimestamp > emissionEndTimestamp
      ? emissionEndTimestamp
      : currentTimestamp;

  const timeDelta = actualCurrentTimestamp - reserveIndexTimestamp;

  let currentReserveIndex;
  if (
    reserveIndexTimestamp == +currentTimestamp ||
    reserveIndexTimestamp >= emissionEndTimestamp
  ) {
    currentReserveIndex = valueToZDBigNumber(reserveIndex);
  } else {
    currentReserveIndex = valueToZDBigNumber(emissionPerSecond)
      .multipliedBy(timeDelta)
      .multipliedBy(pow10(precision))
      .dividedBy(totalSupply)
      .plus(reserveIndex);
  }

  const reward = valueToZDBigNumber(principalUserBalance)
    .multipliedBy(currentReserveIndex.minus(userIndex))
    .dividedBy(pow10(precision));

  return normalize(reward, rewardTokenDecimals);
}

export function calculateMaxWithdrawalAmountInDeleverage(
  userTotalCollateral: string,
  userTotalDebt: string,
  currentLiquidationThreshold: string,
  collateralBalanceInSturdy: string,
  collateralLiquidationThreshold: string,
  repayAssetDebt: string
): string {
  // Calculate max available repay amount: D = W * T
  const maxAvailableRepayAmount = new BigNumber(
    collateralBalanceInSturdy
  ).multipliedBy(collateralLiquidationThreshold);
  const removedDebt = BigNumber.min(
    maxAvailableRepayAmount,
    new BigNumber(repayAssetDebt)
  );

  if (new BigNumber(userTotalDebt).minus(removedDebt).abs().lt('0.0001')) {
    // Rmax = Math.min(C * Ta / T, Wmax) - D
    const withdrawalAmount = BigNumber.min(
      new BigNumber(userTotalCollateral)
        .multipliedBy(currentLiquidationThreshold)
        .dividedBy(collateralLiquidationThreshold),
      collateralBalanceInSturdy
    );
    return BigNumber.max(0, withdrawalAmount.minus(removedDebt)).toString();
  }

  // Rmax = Math.min([C * Ta - 1.05 * (D - Db)] / T, Wmax) - D
  const healthFactorLimit = '1.05';
  const withdrawalAmount = new BigNumber(userTotalCollateral)
    .multipliedBy(currentLiquidationThreshold)
    .minus(
      new BigNumber(healthFactorLimit).multipliedBy(
        new BigNumber(userTotalDebt).minus(removedDebt)
      )
    )
    .dividedBy(collateralLiquidationThreshold);

  return BigNumber.max(
    0,
    BigNumber.min(withdrawalAmount, collateralBalanceInSturdy).minus(
      removedDebt
    )
  ).toString();
}
