import { Configuration, ProtocolAction } from '../tx-builder/types';
import { getGasPrice } from '../tx-builder/utils/gasStation';
import { gasLimitRecommendations } from '../tx-builder/config';
import { valueToBigNumber, normalize } from '..';

const calculateGasPrice = async (config: Configuration) => {
  const gasPrice = await getGasPrice(config);
  return gasPrice;
};

export async function calculateTxPriceEstimation(
  config: Configuration,
  marketRefPriceInUsd: string,
  action: string = ProtocolAction.default
): Promise<string> {
  const gasPrice = await calculateGasPrice(config);
  let totalGas = '0';
  if (action === ProtocolAction.enterPositionWithFlashloan) {
    totalGas = valueToBigNumber('2760000').toString();
  } else {
    totalGas = gasLimitRecommendations[action].recommended;
  }
  const estimationCost = normalize(
    valueToBigNumber(totalGas).times(gasPrice.toString()),
    18
  );
  return valueToBigNumber(estimationCost).div(marketRefPriceInUsd).toFixed(2);
}
