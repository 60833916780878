import {
  CONFIG as WIDO_CONFIG,
  getWidoContractAddress,
  ChainId as WidoChainId,
  QuoteApiParams,
  QuoteApiResponse,
  SameChainOrder,
  CallData,
} from 'wido';
import { IGeneralVault, IGeneralVault__factory } from '../contract-types';
import IERC20ServiceInterface from '../interfaces/ERC20';
import WidoServiceInterface from '../interfaces/Wido';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
  ChainId,
  tStringDecimalUnits,
  ProtocolAction,
} from '../types';
import { WidoParamsType } from '../types/WidoMethodTypes';
import { WidoValidator } from '../validators/methodValidators';
import { IsEthAddress, IsPositiveAmount } from '../validators/paramValidators';
import { API_ETH_MOCK_ADDRESS } from '../config';
import { getTxValue, parseNumber } from '../utils/parsings';
import BaseService from './BaseService';
import { Contract, ContractInterface } from 'ethers';
import widoRouterAbi from './abi/IWidoRouter.json';

const isWidoChainId = (value: number) => {
  const allowedKeys: number[] = [
    1, 250, 43114, 42161, 5, 1285, 137, 42220, 1338, 10, 56,
  ];
  return allowedKeys.includes(value);
};

export default class WidoService
  extends BaseService<IGeneralVault>
  implements WidoServiceInterface
{
  readonly erc20Service: IERC20ServiceInterface;

  readonly widoAddress: string;

  constructor(config: Configuration, erc20Service: IERC20ServiceInterface) {
    super(config, IGeneralVault__factory);

    this.erc20Service = erc20Service;

    // const network = config.network === 'fork' ? 'mainnet' : config.network;
    const chainId = ChainId[config.network];
    if (isWidoChainId(chainId)) {
      this.widoAddress = getWidoContractAddress(chainId as WidoChainId) || '';
    }
  }

  private async getQuote(paramsObj: QuoteApiParams) {
    const endpoint = 'quote';
    const params = new URLSearchParams(paramsObj);
    const url = `${WIDO_CONFIG.WIDO_API_URL}/${endpoint}?${params}`;
    // const url = `https://api.joinwido.com/is-supported/${chainId}/${fromToken}/${chainId}/${toToken}`;

    const res = await fetch(url);

    if (!res.ok) {
      throw new Error(`Failed calling quote: ${res.status} ${res.statusText}.`);
    }

    const body: QuoteApiResponse = await res.json();

    return body;
  }

  @WidoValidator
  public async executeOrder(
    @IsEthAddress('user')
    @IsEthAddress('fromToken')
    @IsEthAddress('toToken')
    @IsPositiveAmount('amount')
    { user, fromToken, toToken, amount }: WidoParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(fromToken);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      amount,
      reserveDecimals
    );

    // const network = this.config.network === 'fork' ? 'mainnet' : this.config.network;
    const chainId = ChainId[this.config.network];
    const paramsObj: QuoteApiParams = {
      from_chain_id: String(chainId),
      from_token: fromToken,
      to_chain_id: String(chainId),
      to_token: toToken,
      user,
      amount: convertedAmount,
    };

    const quoteResult = await this.getQuote(paramsObj);
    const { min_to_token_amount, call_data } = quoteResult;
    const sameChainData = call_data as CallData;

    const order: SameChainOrder = {
      user,
      fromToken,
      toToken,
      fromTokenAmount: convertedAmount,
      minToTokenAmount: min_to_token_amount,
      nonce: 0, // TODO: shouldn't this be string?
      expiration: 0, // TODO: shouldn't this be string?
    };

    if (fromToken.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()) {
      const approved = await isApproved(
        fromToken,
        user,
        this.widoAddress,
        convertedAmount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended = approve(
          user,
          fromToken,
          this.widoAddress,
          convertedAmount
        );
        txs.push(approveTx);
      }
    }

    const widoContract = new Contract(
      this.widoAddress,
      widoRouterAbi as unknown as ContractInterface
    );
    const methodName =
      'executeOrder((address,address,address,uint256,uint256,uint32,uint32),(address,address,address,bytes,int32)[])';
    const callArgs = [order, sameChainData.swap_route];

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        widoContract.populateTransaction[methodName](...callArgs),
      from: user,
      value: getTxValue(fromToken, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.executeOrder
      ),
    });

    return txs;
  }
}
