import { constants } from 'ethers';
import {
  API_ETH_MOCK_ADDRESS,
  DEFAULT_APPROVE_AMOUNT,
  MAX_UINT_AMOUNT,
} from '../../config';
import { ILendingPool, ILendingPool__factory } from '../../contract-types';
import IERC20ServiceInterface from '../../interfaces/ERC20';
import LendingPoolInterface from '../../interfaces/v2/LendingPool';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  InterestRate,
  ProtocolAction,
  TokenMetadataType,
  transactionType,
  tStringDecimalUnits,
  LendingPoolMarketConfig,
} from '../../types';
import { getTxValue, parseNumber } from '../../utils/parsings';
import { LPValidator } from '../../validators/methodValidators';
import {
  LPBorrowParamsType,
  LPDepositParamsType,
  LPLiquidationCall,
  LPRepayParamsType,
  LPWithdrawParamsType,
} from '../../types/LendingPoolMethodTypes';
import {
  IsEthAddress,
  IsPositiveAmount,
  IsPositiveOrMinusOneAmount,
} from '../../validators/paramValidators';
import BaseService from '../BaseService';

export default class LendingPool
  extends BaseService<ILendingPool>
  implements LendingPoolInterface
{
  readonly market: string;

  readonly erc20Service: IERC20ServiceInterface;

  readonly lendingPoolAddress: string;

  readonly lendingPoolConfig: LendingPoolMarketConfig | undefined;

  constructor(
    config: Configuration,
    erc20Service: IERC20ServiceInterface,
    market: string,
    lendingPoolConfig: LendingPoolMarketConfig | undefined
  ) {
    super(config, ILendingPool__factory);
    this.erc20Service = erc20Service;
    this.market = market;
    this.lendingPoolConfig = lendingPoolConfig;

    const { LENDING_POOL } = this.lendingPoolConfig || {};

    this.lendingPoolAddress = LENDING_POOL || '';
  }

  @LPValidator
  public async deposit(
    @IsEthAddress('user')
    @IsEthAddress('reserve')
    @IsPositiveAmount('amount')
    @IsEthAddress('onBehalfOf')
    { user, reserve, amount, onBehalfOf, referralCode }: LPDepositParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    if (reserve.toLowerCase() === API_ETH_MOCK_ADDRESS.toLowerCase()) {
      throw new Error('Not valid token');
    }
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(reserve);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      amount,
      reserveDecimals
    );

    const approved = await isApproved(
      reserve,
      user,
      this.lendingPoolAddress,
      amount
    );
    if (!approved) {
      const approveTx: EthereumTransactionTypeExtended = approve(
        user,
        reserve,
        this.lendingPoolAddress,
        DEFAULT_APPROVE_AMOUNT
      );
      txs.push(approveTx);
    }

    const lendingPoolContract: ILendingPool = this.getContractInstance(
      this.lendingPoolAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        lendingPoolContract.populateTransaction.deposit(
          reserve,
          convertedAmount,
          onBehalfOf || user,
          referralCode || '0'
        ),
      from: user,
      value: getTxValue(reserve, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.deposit
      ),
    });

    return txs;
  }

  @LPValidator
  public async withdraw(
    @IsEthAddress('user')
    @IsEthAddress('reserve')
    @IsPositiveOrMinusOneAmount('amount')
    @IsEthAddress('onBehalfOf')
    { user, reserve, amount, onBehalfOf }: LPWithdrawParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    if (reserve.toLowerCase() === API_ETH_MOCK_ADDRESS.toLowerCase()) {
      throw new Error('Invalid token to withdraw');
    }
    const { decimalsOf }: IERC20ServiceInterface = this.erc20Service;
    const decimals: number = await decimalsOf(reserve);

    const convertedAmount: tStringDecimalUnits =
      amount === '-1'
        ? constants.MaxUint256.toString()
        : parseNumber(amount, decimals);

    const lendingPoolContract: ILendingPool = this.getContractInstance(
      this.lendingPoolAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        lendingPoolContract.populateTransaction.withdraw(
          reserve,
          convertedAmount,
          onBehalfOf || user
        ),
      from: user,
      action: ProtocolAction.withdraw,
    });

    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.DLP_ACTION,
        gas: this.generateTxPriceEstimation(
          [],
          txCallback,
          ProtocolAction.withdraw
        ),
      },
    ];
  }

  @LPValidator
  public async borrow(
    @IsEthAddress('user')
    @IsEthAddress('reserve')
    @IsPositiveAmount('amount')
    @IsEthAddress('debtTokenAddress')
    @IsEthAddress('onBehalfOf')
    {
      user,
      reserve,
      amount,
      interestRateMode,
      onBehalfOf,
      referralCode,
    }: LPBorrowParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    if (reserve.toLowerCase() === API_ETH_MOCK_ADDRESS.toLowerCase()) {
      throw new Error(`Invalid borrow token`);
    }
    const { decimalsOf }: IERC20ServiceInterface = this.erc20Service;
    const reserveDecimals = await decimalsOf(reserve);
    const formatAmount: tStringDecimalUnits = parseNumber(
      amount,
      reserveDecimals
    );

    const numericRateMode = interestRateMode === InterestRate.Variable ? 2 : 1;

    const lendingPoolContract = this.getContractInstance(
      this.lendingPoolAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        lendingPoolContract.populateTransaction.borrow(
          reserve,
          formatAmount,
          numericRateMode,
          referralCode || 0,
          onBehalfOf || user
        ),
      from: user,
      action: ProtocolAction.borrow,
    });

    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.DLP_ACTION,
        gas: this.generateTxPriceEstimation(
          [],
          txCallback,
          ProtocolAction.borrow
        ),
      },
    ];
  }

  @LPValidator
  public async repay(
    @IsEthAddress('user')
    @IsEthAddress('reserve')
    @IsPositiveOrMinusOneAmount('amount')
    @IsEthAddress('onBehalfOf')
    { user, reserve, amount, interestRateMode, onBehalfOf }: LPRepayParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    if (reserve.toLowerCase() === API_ETH_MOCK_ADDRESS.toLowerCase()) {
      throw new Error(`Invalid repay token`);
    }
    const txs: EthereumTransactionTypeExtended[] = [];
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;

    const lendingPoolContract = this.getContractInstance(
      this.lendingPoolAddress
    );
    const { populateTransaction }: ILendingPool = lendingPoolContract;
    const numericRateMode = interestRateMode === InterestRate.Variable ? 2 : 1;
    const decimals: number = await decimalsOf(reserve);

    const convertedAmount: tStringDecimalUnits =
      amount === '-1'
        ? constants.MaxUint256.toString()
        : parseNumber(amount, decimals);

    const approved: boolean = await isApproved(
      reserve,
      user,
      this.lendingPoolAddress,
      amount
    );

    if (!approved) {
      const approveTx: EthereumTransactionTypeExtended = approve(
        user,
        reserve,
        this.lendingPoolAddress,
        DEFAULT_APPROVE_AMOUNT
      );
      txs.push(approveTx);
    }

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        populateTransaction.repay(
          reserve,
          convertedAmount,
          numericRateMode,
          onBehalfOf || user
        ),
      from: user,
      value: getTxValue(reserve, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.repay
      ),
    });

    return txs;
  }

  @LPValidator
  public async liquidationCall(
    @IsEthAddress('liquidator')
    @IsEthAddress('liquidatedUser')
    @IsEthAddress('debtReserve')
    @IsEthAddress('collateralReserve')
    @IsPositiveAmount('purchaseAmount')
    {
      liquidator,
      liquidatedUser,
      debtReserve,
      collateralReserve,
      purchaseAmount,
      getAToken,
      liquidateAll,
    }: LPLiquidationCall
  ): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];
    const { isApproved, approve, getTokenData }: IERC20ServiceInterface =
      this.erc20Service;

    const approved = await isApproved(
      debtReserve,
      liquidator,
      this.lendingPoolAddress,
      purchaseAmount
    );

    if (!approved) {
      const approveTx: EthereumTransactionTypeExtended = approve(
        liquidator,
        debtReserve,
        this.lendingPoolAddress,
        DEFAULT_APPROVE_AMOUNT
      );

      txs.push(approveTx);
    }

    const [debtReserveInfo]: TokenMetadataType[] = await Promise.all([
      getTokenData(debtReserve),
    ]);

    const reserveDecimals: number = debtReserveInfo.decimals;

    const convertedAmount: tStringDecimalUnits = liquidateAll
      ? MAX_UINT_AMOUNT
      : parseNumber(purchaseAmount, reserveDecimals);

    const lendingPoolContract = this.getContractInstance(
      this.lendingPoolAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        lendingPoolContract.populateTransaction.liquidationCall(
          collateralReserve,
          debtReserve,
          liquidatedUser,
          convertedAmount,
          getAToken || false
        ),
      from: liquidator,
      value: getTxValue(debtReserve, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.liquidationCall
      ),
    });

    return txs;
  }
}
