// eslint-disable-next-line import/prefer-default-export
export const assetsOrder = [
  'MATIC',
  'WMATIC',
  'DAI',
  'UNIDAI',
  'GUSD',
  'SUSD',
  'TUSD',
  'USDC',
  'UNIUSDC',
  'USDP',
  'USDT',
  'UNIUSDT',
  'BAL',
  'ETH',
  'WETH',
  'SETH',
  'LINK',
  'MKR',
  'RAI',
  'UNI',
  'WBTC',
  'XSUSHI',
  'YFI',
  'BUSD',
  'FEI',
  'FRAX',
  'AAVE',
  'AMPL',
  'BAT',
  'CRV',
  'DPI',
  'ENJ',
  'KNC',
  'LEND',
  'MANA',
  'PAX',
  'REN',
  'RENFIL',
  'REP',
  'SNX',
  'ZRX',

  'UNIDAIUSDC',
  'UNIUSDCDAI',
  'UNIWBTCUSDC',
  'UNIDAIETH',
  'UNIDAIWETH',
  'UNIUSDCETH',
  'UNIUSDCWETH',
  'UNIAAVEWETH',
  'UNIBATWETH',
  'UNICRVWETH',
  'UNISETHETH',
  'UNILENDETH',
  'UNILINKETH',
  'UNILINKWETH',
  'UNIMKRETH',
  'UNIMKRWETH',
  'UNIRENWETH',
  'UNISNXWETH',
  'UNIUNIWETH',
  'UNIWBTCWETH',
  'UNIYFIWETH',

  'BPTBALWETH',
  'BPTDPIWBTCWETH',
  'BPTLINKWETH',
  'BPTMKRWETH',
  'BPTSNXWETH',
  'BPTUSDCWETH',
  'BPTWBTCWETH',
  'BPTWETHDAI',
  'BPTWETHUSDT',
  'BPTYFIWETH',

  'STETH',
  'WFTM',
  'FTM',
];
