import {
  IGeneralLevSwap,
  IGeneralLevSwap__factory,
} from '../../contract-types';
import IERC20ServiceInterface from '../../interfaces/ERC20';
import BaseDebtTokenInterface from '../../interfaces/BaseDebtToken';
import GeneralLevSwapInterface from '../../interfaces/v2/GeneralLevSwap';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  LeverageSwapConfig,
  ProtocolAction,
  transactionType,
  tStringDecimalUnits,
} from '../../types';

import { LeverageSwapValidator } from '../../validators/methodValidators';
import {
  GLBeforeEnterPositionParamsType,
  GLEnterPositionWithFlashloanParamsType,
  GLWithdrawWithFlashloanParamsType,
  GLZapDepositParamsType,
  GLZapLeverageWithFlashloanParamsType,
} from '../../types/GeneralLevSwapMethodTypes';
import {
  IsEthAddress,
  IsPositiveAmount,
} from '../../validators/paramValidators';
import BaseService from '../BaseService';
import { API_ETH_MOCK_ADDRESS, DEFAULT_APPROVE_AMOUNT } from '../../config';
import { getTxValue, parseNumber } from '../../utils/parsings';

export default class GeneralLevSwap
  extends BaseService<IGeneralLevSwap>
  implements GeneralLevSwapInterface
{
  readonly erc20Service: IERC20ServiceInterface;
  readonly baseDebtTokenService: BaseDebtTokenInterface;
  readonly levSwapAddress: string;
  readonly levSwapConfig: LeverageSwapConfig | undefined;

  constructor(
    config: Configuration,
    erc20Service: IERC20ServiceInterface,
    baseDebtTokenService: BaseDebtTokenInterface,
    levSwapConfig: LeverageSwapConfig | undefined
  ) {
    super(config, IGeneralLevSwap__factory);
    this.erc20Service = erc20Service;
    this.baseDebtTokenService = baseDebtTokenService;
    this.levSwapConfig = levSwapConfig;

    const { LEVSWAPPER } = this.levSwapConfig || {};

    this.levSwapAddress = LEVSWAPPER || '';
  }

  @LeverageSwapValidator
  public async beforeEnterPosition(
    @IsEthAddress('_user')
    @IsEthAddress('_debtTokenAddress')
    @IsPositiveAmount('_amount')
    { _user, _debtTokenAddress, _amount }: GLBeforeEnterPositionParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const txs: EthereumTransactionTypeExtended[] = [];

    if (
      _debtTokenAddress.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()
    ) {
      const approved = await this.baseDebtTokenService.isDelegationApproved(
        _debtTokenAddress,
        _user,
        this.levSwapAddress,
        _amount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended =
          this.baseDebtTokenService.approveDelegation(
            _user,
            this.levSwapAddress,
            _debtTokenAddress,
            DEFAULT_APPROVE_AMOUNT
          );
        txs.push(approveTx);
      }
    }

    return txs;
  }

  @LeverageSwapValidator
  public async enterPositionWithFlashloan(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveAmount('_amount')
    @IsPositiveAmount('_leverage')
    @IsPositiveAmount('_slippage')
    @IsEthAddress('_stableAsset')
    {
      _user,
      _asset,
      _amount,
      _leverage,
      _slippage,
      _stableAsset,
    }: GLEnterPositionWithFlashloanParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(_asset);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      _amount,
      reserveDecimals
    );

    if (_asset.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()) {
      const approved = await isApproved(
        _asset,
        _user,
        this.levSwapAddress,
        _amount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended = approve(
          _user,
          _asset,
          this.levSwapAddress,
          DEFAULT_APPROVE_AMOUNT
        );
        txs.push(approveTx);
      }
    }

    const levSwapContract: IGeneralLevSwap = this.getContractInstance(
      this.levSwapAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        levSwapContract.populateTransaction.enterPositionWithFlashloan(
          convertedAmount,
          _leverage,
          _slippage,
          _stableAsset,
          1 //Balancer
        ),
      from: _user,
      value: getTxValue(_asset, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.enterPositionWithFlashloan
      ),
    });

    return txs;
  }

  @LeverageSwapValidator
  public async withdrawWithFlashloan(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveAmount('_repayAmount')
    @IsPositiveAmount('_withdrawAmount')
    @IsEthAddress('_sasset')
    @IsPositiveAmount('_sassetAmount')
    @IsPositiveAmount('_slippage1')
    @IsPositiveAmount('_slippage2')
    @IsEthAddress('_stableAsset')
    {
      _user,
      _asset,
      _repayAmount,
      _withdrawAmount,
      _sasset,
      _sassetAmount,
      _slippage2,
      _stableAsset,
    }: GLWithdrawWithFlashloanParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];

    const _stableDecimals: number = await decimalsOf(_stableAsset);
    const convertedStableAmount: tStringDecimalUnits = parseNumber(
      _repayAmount,
      _stableDecimals
    );

    const reserveDecimals: number = await decimalsOf(_asset);
    const convertedCollateralAmount: tStringDecimalUnits = parseNumber(
      _withdrawAmount,
      reserveDecimals
    );

    if (_sasset.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()) {
      const approved = await isApproved(
        _sasset,
        _user,
        this.levSwapAddress,
        _sassetAmount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended = approve(
          _user,
          _sasset,
          this.levSwapAddress,
          DEFAULT_APPROVE_AMOUNT
        );
        txs.push(approveTx);
      }
    }

    const levSwapContract: IGeneralLevSwap = this.getContractInstance(
      this.levSwapAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        levSwapContract.populateTransaction.withdrawWithFlashloan(
          convertedStableAmount,
          convertedCollateralAmount,
          _slippage2,
          _stableAsset,
          _sasset,
          1 //Balancer
        ),
      from: _user,
      value: getTxValue(_asset, convertedCollateralAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.withdrawWithFlashloan
      ),
    });

    return txs;
  }

  @LeverageSwapValidator
  public async zapDeposit(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveAmount('_amount')
    { _user, _asset, _amount }: GLZapDepositParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(_asset);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      _amount,
      reserveDecimals
    );

    if (_asset.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()) {
      const approved = await isApproved(
        _asset,
        _user,
        this.levSwapAddress,
        _amount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended = approve(
          _user,
          _asset,
          this.levSwapAddress,
          DEFAULT_APPROVE_AMOUNT
        );
        txs.push(approveTx);
      }
    }

    const levSwapContract: IGeneralLevSwap = this.getContractInstance(
      this.levSwapAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        levSwapContract.populateTransaction.zapDeposit(_asset, convertedAmount),
      from: _user,
      value: getTxValue(_asset, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.zapDeposit
      ),
    });

    return txs;
  }

  @LeverageSwapValidator
  public async zapLeverageWithFlashloan(
    @IsEthAddress('_user')
    @IsEthAddress('_asset')
    @IsPositiveAmount('_amount')
    @IsPositiveAmount('_leverage')
    @IsPositiveAmount('_slippage')
    @IsEthAddress('_borrowAsset')
    {
      _user,
      _asset,
      _amount,
      _leverage,
      _slippage,
      _borrowAsset,
    }: GLZapLeverageWithFlashloanParamsType
  ): Promise<EthereumTransactionTypeExtended[]> {
    const { isApproved, approve, decimalsOf }: IERC20ServiceInterface =
      this.erc20Service;
    const txs: EthereumTransactionTypeExtended[] = [];
    const reserveDecimals: number = await decimalsOf(_asset);
    const convertedAmount: tStringDecimalUnits = parseNumber(
      _amount,
      reserveDecimals
    );

    if (_asset.toLowerCase() !== API_ETH_MOCK_ADDRESS.toLowerCase()) {
      const approved = await isApproved(
        _asset,
        _user,
        this.levSwapAddress,
        _amount
      );
      if (!approved) {
        const approveTx: EthereumTransactionTypeExtended = approve(
          _user,
          _asset,
          this.levSwapAddress,
          DEFAULT_APPROVE_AMOUNT
        );
        txs.push(approveTx);
      }
    }

    const levSwapContract: IGeneralLevSwap = this.getContractInstance(
      this.levSwapAddress
    );

    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: () =>
        levSwapContract.populateTransaction.zapLeverageWithFlashloan(
          _asset,
          convertedAmount,
          _leverage,
          _slippage,
          _borrowAsset,
          1 //Balancer
        ),
      from: _user,
      value: getTxValue(_asset, convertedAmount),
    });

    txs.push({
      tx: txCallback,
      txType: eEthereumTxType.DLP_ACTION,
      gas: this.generateTxPriceEstimation(
        txs,
        txCallback,
        ProtocolAction.zapLeverageWithFlashloan
      ),
    });

    return txs;
  }
}
