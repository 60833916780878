/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { IGeneralLevSwap } from '../IGeneralLevSwap';

export class IGeneralLevSwap__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IGeneralLevSwap {
    return new Contract(address, _abi, signerOrProvider) as IGeneralLevSwap;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_principal',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_leverage',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_slippage',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_stableAsset',
        type: 'address',
      },
      {
        internalType: 'enum IGeneralLevSwap.FlashLoanType',
        name: '_flashLoanType',
        type: 'uint8',
      },
    ],
    name: 'enterPositionWithFlashloan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getAvailableStableCoins',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'pure',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_repayAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_requiredAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_slippage',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_stableAsset',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_sAsset',
        type: 'address',
      },
      {
        internalType: 'enum IGeneralLevSwap.FlashLoanType',
        name: '_flashLoanType',
        type: 'uint8',
      },
    ],
    name: 'withdrawWithFlashloan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_zappingAsset',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_principal',
        type: 'uint256',
      },
    ],
    name: 'zapDeposit',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_zappingAsset',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: '_principal',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_leverage',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_slippage',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: '_borrowAsset',
        type: 'address',
      },
      {
        internalType: 'enum IGeneralLevSwap.FlashLoanType',
        name: '_flashLoanType',
        type: 'uint8',
      },
    ],
    name: 'zapLeverageWithFlashloan',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
