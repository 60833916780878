/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { ISturdyIncentivesController } from '../ISturdyIncentivesController';

export class ISturdyIncentivesController__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): ISturdyIncentivesController {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as ISturdyIncentivesController;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'assets',
        type: 'address[]',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
      {
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
    ],
    name: 'claimRewards',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
