import { providers } from 'ethers';
import { Network, DefaultProviderKeys, TxBuilderConfig } from './types';
import TxBuilderInterface from './interfaces/TxBuilder';
import LendingPoolInterface from './interfaces/v2/LendingPool';
import LendingPool from './services/v2/LendingPool';
import GeneralVaultInterface from './interfaces/v2/GeneralVault';
import LidoVault from './services/v2/LidoVault';
import BaseTxBuilder from './txBuilder';
import BaseDebtTokenInterface from './interfaces/BaseDebtToken';
import BaseDebtToken from './services/BaseDebtToken';
import YearnVault from './services/v2/YearnVault';
import YearnWETHVault from './services/v2/YearnWETHVault';
import YearnWBTCVault from './services/v2/YearnWBTCVault';
import YearnBOOVault from './services/v2/YearnBOOVault';
import YearnFBeetsVault from './services/v2/YearnFBeetsVault';
import YearnLINKVault from './services/v2/YearnLINKVault';
import YearnSPELLVault from './services/v2/YearnSPELLVault';
import YearnCRVVault from './services/v2/YearnCRVVault';
import TombFtmBeefyVault from './services/v2/TombFtmBeefyVault';
import TombMiMaticBeefyVault from './services/v2/TombMiMaticBeefyVault';
import BasedMiMaticBeefyVault from './services/v2/BasedMiMaticBeefyVault';
import ConvexCurveLPVault from './services/v2/ConvexCurveLPVault';
import GeneralLevSwapInterface from './interfaces/v2/GeneralLevSwap';
import GeneralLevSwap from './services/v2/GeneralLevSwap';

export default class TxBuilder
  extends BaseTxBuilder
  implements TxBuilderInterface
{
  readonly lendingPools: {
    [market: string]: LendingPoolInterface;
  };

  lidoVault: GeneralVaultInterface;
  convexFRAX3CRVVault: GeneralVaultInterface;
  convexDAIUSDCUSDTSUSDVault: GeneralVaultInterface;
  convexIronBankVault: GeneralVaultInterface;
  convexFRAXUSDCVault: GeneralVaultInterface;
  convexMIM3CRVVault: GeneralVaultInterface;
  convexTUSDFRAXBPVault: GeneralVaultInterface;
  yearnVault: GeneralVaultInterface;
  yearnWETHVault: GeneralVaultInterface;
  yearnWBTCVault: GeneralVaultInterface;
  yearnBOOVault: GeneralVaultInterface;
  yearnFBeetsVault: GeneralVaultInterface;
  yearnLINKVault: GeneralVaultInterface;
  yearnSPELLVault: GeneralVaultInterface;
  yearnCRVVault: GeneralVaultInterface;
  tombFtmBeefyVault: GeneralVaultInterface;
  tombMiMaticBeefyVault: GeneralVaultInterface;
  basedMiMaticBeefyVault: GeneralVaultInterface;

  convexFRAX3CRVLevSwap: GeneralLevSwapInterface;
  convexDAIUSDCUSDTSUSDLevSwap: GeneralLevSwapInterface;
  convexIRONBANKLevSwap: GeneralLevSwapInterface;
  convexFRAXUSDCLevSwap: GeneralLevSwapInterface;
  convexMIM3CRVLevSwap: GeneralLevSwapInterface;
  convexTUSDFRAXBPLevSwap: GeneralLevSwapInterface;

  readonly baseDebtTokenService: BaseDebtTokenInterface;

  constructor(
    network: Network = Network.mainnet,
    injectedProvider?: providers.Provider | string | undefined,
    defaultProviderKeys?: DefaultProviderKeys,
    config?: TxBuilderConfig
  ) {
    super(network, injectedProvider, defaultProviderKeys, config);

    this.lendingPools = {};
    this.baseDebtTokenService = new BaseDebtToken(
      this.configuration,
      this.erc20Service
    );
  }

  public getLendingPool = (market: string): LendingPoolInterface => {
    const { network } = this.configuration;
    if (!this.lendingPools[market]) {
      this.lendingPools[market] = new LendingPool(
        this.configuration,
        this.erc20Service,
        market,
        this.txBuilderConfig.lendingPool?.[network]?.[market]
      );
    }

    return this.lendingPools[market];
  };

  public getLidoVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.lidoVault) {
      this.lidoVault = new LidoVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.lidoVault?.[network]
      );
    }

    return this.lidoVault;
  };

  public getYearnVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnVault) {
      this.yearnVault = new YearnVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnVault?.[network]
      );
    }

    return this.yearnVault;
  };

  public getYearnWETHVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnWETHVault) {
      this.yearnWETHVault = new YearnWETHVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnWETHVault?.[network]
      );
    }

    return this.yearnWETHVault;
  };

  public getYearnWBTCVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnWBTCVault) {
      this.yearnWBTCVault = new YearnWBTCVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnWBTCVault?.[network]
      );
    }

    return this.yearnWBTCVault;
  };

  public getYearnBOOVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnBOOVault) {
      this.yearnBOOVault = new YearnBOOVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnBOOVault?.[network]
      );
    }

    return this.yearnBOOVault;
  };

  public getYearnFBeetsVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnFBeetsVault) {
      this.yearnFBeetsVault = new YearnFBeetsVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnFBeetsVault?.[network]
      );
    }

    return this.yearnFBeetsVault;
  };

  public getYearnLINKVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnLINKVault) {
      this.yearnLINKVault = new YearnLINKVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnLINKVault?.[network]
      );
    }

    return this.yearnLINKVault;
  };

  public getYearnSPELLVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnSPELLVault) {
      this.yearnSPELLVault = new YearnSPELLVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnSPELLVault?.[network]
      );
    }

    return this.yearnSPELLVault;
  };

  public getYearnCRVVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.yearnCRVVault) {
      this.yearnCRVVault = new YearnCRVVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.yearnCRVVault?.[network]
      );
    }

    return this.yearnCRVVault;
  };

  public getTombFtmBeefyVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.tombFtmBeefyVault) {
      this.tombFtmBeefyVault = new TombFtmBeefyVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.tombFtmBeefyVault?.[network]
      );
    }

    return this.tombFtmBeefyVault;
  };

  public getTombMiMaticBeefyVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.tombMiMaticBeefyVault) {
      this.tombMiMaticBeefyVault = new TombMiMaticBeefyVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.tombMiMaticBeefyVault?.[network]
      );
    }

    return this.tombMiMaticBeefyVault;
  };

  public getBasedMiMaticBeefyVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.basedMiMaticBeefyVault) {
      this.basedMiMaticBeefyVault = new BasedMiMaticBeefyVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.basedMiMaticBeefyVault?.[network]
      );
    }

    return this.basedMiMaticBeefyVault;
  };

  public getConvexFRAX3CRVVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexFRAX3CRVVault) {
      this.convexFRAX3CRVVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexFRAX3CRVVault?.[network]
      );
    }

    return this.convexFRAX3CRVVault;
  };

  public getConvexIronBankVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexIronBankVault) {
      this.convexIronBankVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexIronBankVault?.[network]
      );
    }

    return this.convexIronBankVault;
  };

  public getConvexDAIUSDCUSDTSUSDVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexDAIUSDCUSDTSUSDVault) {
      this.convexDAIUSDCUSDTSUSDVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexDAIUSDCUSDTSUSDVault?.[network]
      );
    }

    return this.convexDAIUSDCUSDTSUSDVault;
  };

  public getConvexFRAXUSDCVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexFRAXUSDCVault) {
      this.convexFRAXUSDCVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexFRAXUSDCVault?.[network]
      );
    }

    return this.convexFRAXUSDCVault;
  };

  public getConvexMIM3CRVVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexMIM3CRVVault) {
      this.convexMIM3CRVVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexMIM3CRVVault?.[network]
      );
    }

    return this.convexMIM3CRVVault;
  };

  public getConvexTUSDFRAXBPVault = (): GeneralVaultInterface => {
    const { network } = this.configuration;
    if (!this.convexTUSDFRAXBPVault) {
      this.convexTUSDFRAXBPVault = new ConvexCurveLPVault(
        this.configuration,
        this.erc20Service,
        this.txBuilderConfig.convexTUSDFRAXBPVault?.[network]
      );
    }

    return this.convexTUSDFRAXBPVault;
  };

  public getConvexFRAX3CRVLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexFRAX3CRVLevSwap) {
      this.convexFRAX3CRVLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexFRAX3CRVLevSwap?.[network]
      );
    }

    return this.convexFRAX3CRVLevSwap;
  };

  public getConvexDAIUSDCUSDTSUSDLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexDAIUSDCUSDTSUSDLevSwap) {
      this.convexDAIUSDCUSDTSUSDLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexDAIUSDCUSDTSUSDLevSwap?.[network]
      );
    }

    return this.convexDAIUSDCUSDTSUSDLevSwap;
  };

  public getConvexFRAXUSDCLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexFRAXUSDCLevSwap) {
      this.convexFRAXUSDCLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexFRAXUSDCLevSwap?.[network]
      );
    }

    return this.convexFRAXUSDCLevSwap;
  };

  public getConvexIRONBANKLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexIRONBANKLevSwap) {
      this.convexIRONBANKLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexIRONBANKLevSwap?.[network]
      );
    }

    return this.convexIRONBANKLevSwap;
  };

  public getConvexMIM3CRVLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexMIM3CRVLevSwap) {
      this.convexMIM3CRVLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexMIM3CRVLevSwap?.[network]
      );
    }

    return this.convexMIM3CRVLevSwap;
  };

  public getConvexTUSDFRAXBPLevSwap = (): GeneralLevSwapInterface => {
    const { network } = this.configuration;
    if (!this.convexTUSDFRAXBPLevSwap) {
      this.convexTUSDFRAXBPLevSwap = new GeneralLevSwap(
        this.configuration,
        this.erc20Service,
        this.baseDebtTokenService,
        this.txBuilderConfig.convexTUSDFRAXBPLevSwap?.[network]
      );
    }

    return this.convexTUSDFRAXBPLevSwap;
  };
}
