/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { IDebtTokenBase } from '../IDebtTokenBase';

export class IDebtTokenBase__factory {
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IDebtTokenBase {
    return new Contract(address, _abi, signerOrProvider) as IDebtTokenBase;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'delegatee',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'approveDelegation',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'fromUser',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'toUser',
        type: 'address',
      },
    ],
    name: 'borrowAllowance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
