import { Themes } from './types';

// eslint-disable-next-line import/prefer-default-export
export const themes: Themes = {
  default: {
    headerBg: { hex: '#0d142d', rgb: [13, 20, 45] },
    mainBg: { hex: '#f1efe4', rgb: [241, 239, 228] }, // cream : lighter dark blue
    primary: { hex: '#29b173', rgb: [182, 80, 158] },
    secondary: { hex: '#23804d', rgb: [46, 186, 198] },
    darkBlue: { hex: '#0d142d', rgb: [13, 20, 45] },
    textDarkBlue: { hex: '#0d142d', rgb: [13, 20, 45] }, // dark blue : cream
    lightBlue: { hex: '#8d8f99', rgb: [141, 143, 153] },
    red: { hex: '#de5959', rgb: [222, 89, 89] },
    orange: { hex: '#ffa048', rgb: [255, 172, 77] },
    green: { hex: '#79c982', rgb: [121, 201, 130] },
    black: { hex: '#000000', rgb: [0, 0, 0] },
    lightGreen: { hex: '#29b173', rgb: [41, 177, 115] },
    white: { hex: '#f1efe4', rgb: [255, 255, 255] }, // cream : cream
    whiteElement: { hex: '#ffffff', rgb: [255, 255, 255] }, // white : dark blue
    whiteItem: { hex: '#ffffff', rgb: [255, 255, 255] }, // white : dark blue
    darkOrange: { hex: '#f0650a', rgb: [240, 101, 10] }, // for deposit APY
    gray: { hex: '#707070', rgb: [112, 112, 112] },
    lightGray: { hex: '#a2a2a2', rgb: [162, 162, 162] },
    disabledGray: { hex: '#e0e0e2', rgb: [224, 224, 226] }, // white gray : dark gray
    cream: { hex: '#f1efe4', rgb: [241, 239, 228] },
    darkBlueLighter: { hex: '#192240', rgb: [25, 34, 64] },
    creamToDark: { hex: '#f1efe4', rgb: [241, 239, 228] },
  },
  dark: {
    headerBg: { hex: '#0d142d', rgb: [13, 20, 45] },
    mainBg: { hex: '#192240', rgb: [25, 34, 64] },
    primary: { hex: '#29b173', rgb: [182, 80, 158] },
    secondary: { hex: '#23804d', rgb: [46, 186, 198] },
    darkBlue: { hex: '#0d142d', rgb: [13, 20, 45] },
    textDarkBlue: { hex: '#f1efe4', rgb: [241, 241, 243] },
    lightBlue: { hex: '#8d8f99', rgb: [141, 143, 153] },
    red: { hex: '#de5959', rgb: [222, 89, 89] },
    orange: { hex: '#ffa048', rgb: [255, 172, 77] },
    green: { hex: '#79c982', rgb: [121, 201, 130] },
    black: { hex: '#000000', rgb: [0, 0, 0] },
    lightGreen: { hex: '#29b173', rgb: [41, 177, 115] },
    white: { hex: '#f1efe4', rgb: [241, 241, 243] },
    whiteElement: { hex: '#0d142d', rgb: [56, 61, 81] },
    whiteItem: { hex: '#0d142d', rgb: [43, 45, 60] },
    darkOrange: { hex: '#f0650a', rgb: [240, 101, 10] }, // for deposit APY
    gray: { hex: '#707070', rgb: [112, 112, 112] },
    lightGray: { hex: '#a2a2a2', rgb: [162, 162, 162] },
    disabledGray: { hex: '#20202e', rgb: [32, 32, 46] },
    cream: { hex: '#f1efe4', rgb: [241, 239, 228] },
    darkBlueLighter: { hex: '#192240', rgb: [25, 34, 64] },
    creamToDark: { hex: '#0d142d', rgb: [13, 20, 45] },
  },
};
