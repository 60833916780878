import { constants } from 'ethers';
import {
  IStableYieldDistribution,
  IStableYieldDistribution__factory,
} from '../contract-types';
import {
  Configuration,
  eEthereumTxType,
  EthereumTransactionTypeExtended,
  transactionType,
} from '../types';
import { YieldDistributionValidator } from '../validators/methodValidators';
import { IsEthAddress, IsEthAddressArray } from '../validators/paramValidators';
import BaseService from './BaseService';

export type StableYieldsMethodType = {
  user: string;
  assets: string[];
  to?: string;
  distributorAddress: string;
};

export interface StableYieldDistributionInterface {
  claimRewards: (
    args: StableYieldsMethodType
  ) => EthereumTransactionTypeExtended[];
}

export default class StableYieldDistribution
  extends BaseService<IStableYieldDistribution>
  implements StableYieldDistributionInterface
{
  constructor(config: Configuration) {
    super(config, IStableYieldDistribution__factory);
  }

  @YieldDistributionValidator
  public claimRewards(
    @IsEthAddress('user')
    @IsEthAddress('distributorAddress')
    @IsEthAddress('to')
    @IsEthAddressArray('assets')
    { user, assets, to, distributorAddress }: StableYieldsMethodType
  ): EthereumTransactionTypeExtended[] {
    const rewardContract: IStableYieldDistribution =
      this.getContractInstance(distributorAddress);
    const txCallback: () => Promise<transactionType> = this.generateTxCallback({
      rawTxMethod: async () =>
        rewardContract.populateTransaction.claimRewards(
          assets,
          constants.MaxUint256.toString(),
          to ?? user
        ),
      from: user,
    });

    return [
      {
        tx: txCallback,
        txType: eEthereumTxType.REWARD_ACTION,
        gas: this.generateTxPriceEstimation([], txCallback),
      },
    ];
  }
}
